@charset "utf-8";


// ヘッダー
.head_all {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: map-get($zindex, 'header');
	&.imp {
		position: absolute;
		header {
			position: static;
		}
	}
	+ .h_bg {
		position: fixed;
		top: 103px;
		left: 0;
		width: 100vw;
		height: calc(100vh - 103px);
		display: block;
		background-color: #000;
		opacity: 0;
		pointer-events: none;
		z-index: 18;
		transition: opacity .2s;
		@include mq(pc) {
			&.act {
				pointer-events: all;
				opacity: .3;
			}
		}
	}
	.h_sinput {
		position: fixed;
		right: 0;
		width: 394px;
		border-bottom-left-radius: 40px;
		border-top-left-radius: 40px;
		top: $height_header;
		background: linear-gradient(to right, #323232, #5e5e5e);
		padding: 15px 30px 15px 15px;
		z-index: 21;
		opacity: 0;
		pointer-events: none;
		transition: opacity .2s;
		&.act {
			opacity: 1;
			pointer-events: all;
		}
		@include mq(sp) {
			border-radius: 0;
			width: 100%;
			padding: spvw(30) spvw(80);
			top: calc((134 / 750) * 100vw);
		}
		label {
			position: relative;
			max-width: 650px;
			width: 100%;
			input {
				width: 100%;
				border-radius: 2.5em;
				padding: .8em 3em .8em 1.6em;
				font-size: 16px;
				box-sizing: border-box;
				border: none;
				&[type="text"] {
					border: none;
					outline: none;
					background-color: $white !important;
					&:focus {
						border: none;
					}
				}
				&[type="submit"] {
					position: absolute;
					top: 50%;
					right: 1.25em;
					transform: translateY(-50%);
					width: 1.25em;
					height: 1.25em;
					padding: 0;
					// background: url(/common/img/ico/ico_search.svg) no-repeat 0 50%/100% auto;
					text-indent: -9999px;
					cursor: pointer;
					background: transparent url(/common/img/icon/search_black.svg) center center / 1.2em no-repeat;
					&:focus {
						border: none;
						outline: none;
					}
				}
				@include mq(sp) {
					font-size: spvw(32);
					&[type="submit"] {
						background: transparent url(/common/img/icon/search_black.svg) center center / cover no-repeat !important;
					}
				}
			}
		}
	}
	header {
		width: 100%;
		position: fixed;
		// transition: all .2s;
		height: $height_header;
		z-index: 20;
		&:after {
			position: absolute;
			content: "";
			width: 100%;
			height: $height_header;
			z-index: +1;
			top: 0;
			left: 0;
			opacity: 1;
			// background: linear-gradient(to right, rgba(185,6,12,1), rgba(213,90,94,1));
			background: transparent url(/common/img/bg_header.png) center center / 100% 100% no-repeat;
			transition: opacity .2s;
			@include mq(sp) {
				height: spvw(134);
			}
		}
		@include mq(sp) {
			background: transparent url(/common/img/bg_header_sp.png) center center /  100% 100% no-repeat;
			height: spvw(134);
		}
	
	
		h1 {
			width: 112px;
			position: absolute;
			top: 23px;
			left: 30px;
			z-index: +3;
			@include mq(sp) {
				width: spvw(158);
				top: spvw(26);
				left: spvw(28);
			}
		}
	
		.h_menu {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			position: relative;
			z-index: +2;
			a,span {
				color: $white;
			}
			@include mq(sp) {
				display: none;
				position: fixed;
				left: 0;
				top: spvw(134);
				background-color: $white;
				width: 100%;
				overflow-y: auto;
				max-height: calc(100vh - ((134 / 750) * 100vw));
				a,span {
					color: $black;
					line-height: 1.3em;
					display: block;
				}
				dl {
					dt {
						span {
							display: none;
						}
					}
				}
			}
			&::after {
				content: "";
				width: 100%;
				order: 4;
			}

			.h_menu01 {
				order: 1;
				margin-top: 10px;
				> ul {
					display: flex;
					li {
						margin: 0 .5em;
						> a {
							font-size: 12px;
							color: $white;
							border: 1px solid $white;
							border-radius: 1.5em;
							padding: .4em 1.4em;
						}
						@include mq(pc) {
							> a {
								transition: background-color .3s;
								&:hover {
									text-decoration: none;
									background-color: $white;
									span {
										color: #d51d1d;
									}
								}
							}
						}
						@include mq(md) {
							margin: 0 calc(0.00976 * 100vw - 5.3172px);
							> a {
								font-size: calc(0.00488 * 100vw + 6.3414px);
								padding: .4em calc(0.03122 * 100vw - 19.41504px);
								> span {
									letter-spacing: calc(0.003171 * 100vw - 2.47809px);
								}
							}
						}
						@include mq(sp) {
							margin: 0;
							height: calc((110 / 750) * 100vw);
							> a {
								border-radius: 0;
								padding: .4em 1.4em;
								font-size: calc((30 / 750) * 100vw);
								// padding: 0 1.3em;
								display: flex;
								height: 100%;
								color: $white;
								align-items: center;
								&::after {
									position: absolute;
									content: "";
									width: 2.5em;
									height: .8em;
									opacity: 1;
									right: 1.2em;
									background: transparent url(/top/img/arrow.svg) right center / auto no-repeat;
								}
								span {
									color: $white;
								}
							}
							&.h_ho {
								background: linear-gradient(to right, rgba(69,69,69,1), rgba(164,164,164,1));
							}
							&.h_ko {
								background: linear-gradient(to right, rgba(208,24,24,1), rgba(236,101,42,1));
							}
							&.h_ji {
								background: linear-gradient(to right, rgba(24,49,135,1), rgba(24,175,227,1));
							}
						}
					}
					@include mq(sp) {
						display: block;
					}
				}
			}



			.h_menu02 {
				order: 5;
				margin-top: 20px;
				margin-right: 60px;
				@include mq(sp) {
					margin: spvw(40) auto 0;
				}
				> ul {
					display: flex;
					@include mq(sp) {
						display: block;
						margin: 0 auto;
						width: spvw(670);
					}
					> li {
						font-size: 16px;
						padding: .2em .6em;
						margin: 0 .5em;
						> a {
							vertical-align: middle;
							display: inline-block;
							text-decoration: none;
							padding-bottom: 0.2em;
							@include mq(sp) {
								display: block;
							}
						}
						@include mq(pc) {
							&.h_ourstory,
							&.h_business,
							&.h_sustainability,
							&.h_ownedmedia {
								position: relative;
								cursor: pointer;
								&::after {
									content: "";
									position: absolute;
									width: 0%;
									height: 4px;
									background-color: $white;
									bottom: -.7em;
									left: 0;
									opacity: 0;
									transition: width .3s, opacity .1s;
								}
								&.act,&:hover {
									&::after {
										width: 100%;
										opacity: 1;
									}	
								}
							}
						}
						@include mq(md) {
							&.h_ourstory,
							&.h_business,
							&.h_sustainability,
							&.h_ownedmedia {
								&::after {
									// bottom: -.8em;
									bottom: calc(0.025 * 100vw - 40px);
								}
							}
							font-size: calc(0.00488 * 100vw + 10.3414px);
							> a {
								> span {
									letter-spacing: calc(0.003171 * 100vw - 2.47809px);
								}
							}
						}
						@include mq(sp) {
							font-size: spvw(36);
							padding: 1.25em 0 1.25em .5em;
							font-weight: 600;
							margin: 0;
							border-bottom: 1px solid #d4d4d4;
							> a {
								position: relative;
								&::after {
									position: absolute;
									content: "";
									width: .5em;
									height: .5em;
									border-right: 2px #3a3a3a solid;
									border-bottom: 2px #3a3a3a solid;
									transform: rotate(-45deg);
									right: .8em;
									top: .3em;
								}
							}
							> span {
								position: relative;
								&::after {
									position: absolute;
									content: "";
									width: .5em;
									height: .5em;
									border-right: 2px #3a3a3a solid;
									border-bottom: 2px #3a3a3a solid;
									transform: rotate(45deg);
									right: .8em;
									top: .3em;
								}
							}
							&.opn {
								span {
									&::after {
										transform: rotate(-135deg);
										top: .4em;
									}
								}
							}
							dl {
								dt {
									a {
										font-weight: 400;
										padding: .7em 0 .7em 1em;
									}
								}
								dd {
									ul {
										> li {
											a {
												font-weight: 400;
												padding: .7em 0 .7em 1em;
											}
										}
									}
								}
							}
						}
					}
				}

				.h_sec_box2 {
					ul {
						li {
							a {
								&.blank {
									&.nex {
										&:after {
											@include mq(sp) {
												right: auto;
												left: 7em;
												bottom: .5em;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.h_menu03 {
				order: 2;
				margin-top: 10px;
				margin-left: 12px;
				@include mq(md) {
					margin-left: calc(0.02439 * 100vw - 16.293px);
				}
				@include mq(sp) {
					margin: 0 auto;
				}
				> ul {
					display: flex;
					@include mq(sp) {
						display: block;
						width: spvw(670);
						margin: 0 auto;
					}
					> li {
						font-size: 12px;
						padding: .4em .4em 0;
						margin: 0 .7em;
						@include mq(pc) {
							&.h_company,
							&.h_recruit {
								position: relative;
								cursor: pointer;
								&::after {
									content: "";
									position: absolute;
									width: 0%;
									height: 1px;
									background-color: $white;
									bottom: 0em;
									left: 0;
									opacity: 0;
									transition: width .3s, opacity .1s;
								}
								&.act,&:hover {
									&::after {
										width: 100%;
										opacity: 1;
									}	
								}
							}
						}
						@include mq(md) {
							&.h_company,
							&.h_recruit {
								position: relative;
								&::after {
									content: "";
									position: absolute;
									width: 0%;
									height: 1px;
									background-color: $white;
									bottom: 0em;
									left: 0;
									opacity: 0;
									transition: width .3s, opacity .1s;
								}
								&.act {
									&::after {
										width: 100%;
										opacity: 1;
									}	
								}
							}
							font-size: calc(0.00488 * 100vw + 6.3414px);
							padding: .4em calc(0.03122 * 100vw - 19.41504px);
							margin: 0 calc(0.00976 * 100vw - 5.3172px);
							padding: .4em calc(0.00585 * 100vw - 1.99032px) 0;
							margin: 0 calc(0.01756 * 100vw - 11.97096px);
							> a {
								> span {
									letter-spacing: calc(0.003171 * 100vw - 2.47809px);
								}
							}
						}
						@include mq(sp) {
							font-size: spvw(36);
							padding: 1.25em 0 1.25em .5em;
							margin: 0;
							font-weight: 600;
							border-bottom: 1px solid #d4d4d4;
							> a {
								position: relative;
								&::after {
									position: absolute;
									content: "";
									width: .5em;
									height: .5em;
									border-right: 2px #3a3a3a solid;
									border-bottom: 2px #3a3a3a solid;
									transform: rotate(-45deg);
									right: .8em;
									top: .3em;
								}
							}
							> span {
								position: relative;
								&::after {
									position: absolute;
									content: "";
									width: .5em;
									height: .5em;
									border-right: 2px #3a3a3a solid;
									border-bottom: 2px #3a3a3a solid;
									transform: rotate(45deg);
									right: .8em;
									top: .3em;
								}
							}
							&.opn {
								span {
									&::after {
										transform: rotate(-135deg);
										top: .4em;
									}
								}
							}
							
							dl {
								dt {
									a {
										font-weight: 400;
										padding: .7em 0 .7em 1em;
									}
								}
								dd {
									ul {
										> li {
											a {
												font-weight: 400;
												padding: .7em 0 .7em 1em;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.h_menu04 {
				order: 3;
				margin-top: 10px;
				@include mq(sp) {
					display: flex;
					justify-content: center;
					// padding-bottom: spvw(65);
					padding-bottom: spvw(195);
				}
				> ul {
					display: flex;
					@include mq(sp) {
						// display: block;
					}
					li {
						font-size: 12px;
						padding: .4em .6em;
						margin: 0 1.5em 0 .5em;
						text-underline-offset: .5em;
						color: $white;
						&:first-child {
							text-decoration: underline;
						}
						&:last-child {
							position: relative;
							&::before {
								position: absolute;
								content: "";
								left: -1em;
								top: .6em;
								height: 1.1em;
								width: 1px;
								background-color: $white;
								transform: rotate(10deg);
							}
						}
						@include mq(md) {
							font-size: calc(0.00488 * 100vw + 6.3414px);
							margin: 0 calc(0.03171 * 100vw - 18.7809px) 0 .5em;
							> a {
								> span {
									letter-spacing: calc(0.003171 * 100vw - 2.47809px);
								}
							}
						}
						@include mq(sp) {
							color: $black;
							font-size: spvw(30);
							&:last-child {
								&::before {
									background-color: $black;
								}
							}
						}
					}
				}
			}
		}
	}
}

/* ヘッダー 英語 */
html[lang=en] {
	.head_all {
		header {
			.h_menu {
				.h_menu04 {
					> ul {
						li {
							&:first-child {
								text-decoration: none;
							}
							&:last-child {
								text-decoration: underline;
							}
						}
					}
				}
				.h_menu02 {
					> ul {
						> li {
							@include mq(pc) {
								&.h_ourstory,
								&.h_business,
								&.h_sustainability {
									&::after {
										bottom: -.95em;
									}
								}
							}
							@include mq(md) {
								&.h_ourstory,
								&.h_business,
								&.h_sustainability {
									&::after {
										bottom: calc(0.022 * 100vw - 40px);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}


#top {
	header {
		&:after {
			opacity: 0;
		}
		&:hover,
		&.kvact,
		&.act {
			&:after {
				opacity: 1;
			}
		}
	}
}
#newsrelease_top {
	main {
		.head_all {
			top: 0;
			position: absolute;
		}
	}
}
.h_search {
	position: absolute;
	display: block;
	top: 57px;
	right: 20px;
	width: 37px;
	height: 37px;
	cursor: pointer;
	background: transparent url(/common/img/icon/search.svg) center center no-repeat;
	background-size: 50%;
	&::before {
		width: 2px;
		height: 50%;
		background-color: $white;
		position: absolute;
		content: "";
		transform: rotate(45deg);
		left: 1.1em;
		top: .5em;
		opacity: 0;
		transition: opacity .2s;
	}
	&::after {
		width: 2px;
		height: 50%;
		background-color: $white;
		position: absolute;
		content: "";
		transform: rotate(-45deg);
		left: 1.1em;
		top: .5em;
		opacity: 0;
		transition: opacity .2s;
	}
	z-index:30;
	&.act {
		background: transparent;
		&::before, &::after {
			opacity: 1;
		}
	}
	@include mq(md) {
		top: calc(0.00732 * 100vw + 49.5121px);
		width: calc(0.02439 * 100vw + 8.707px);
		height: calc(0.02439 * 100vw + 8.707px);
	}
	@include mq(sp) {
		background-size: 100%;
		top: spvw(40);
		right: spvw(195);
		width: spvw(52);
		height: spvw(52);
		margin: 0;
		&::before,
		&::after {
			height: 100%;
			left: 0.8em;
			top: 0em;
		}
	}
}
.h_humb {
	display: none;
	@include mq(sp) {
		// position: fixed;
		position: absolute;
		top: spvw(40);
		right: spvw(40);
		display: block;
		z-index:30;
		pointer-events: all;
		> i {
			height: spvw(4);
			width: spvw(93);
			display: block;
			background-color: $white;
			opacity: 1;
			transition: transform .2s;
			&:nth-child(1) {
				transform-origin: right top;
			}
			&:nth-child(2) {
				margin-top: spvw(18);
				transform-origin: right;
			}
			&:nth-child(3) {
				margin-top: spvw(18);
				transform-origin: right bottom;
			}
		}
		&.act {
			> i {
				&:nth-child(1) {
					width: spvw(100);
					transform: rotate(-26deg);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:nth-child(3) {
					width: spvw(100);
					transform: rotate(26deg);
				}
			}
		}
		&.pointnone {
			pointer-events: none;
		}
	}
}
.h_sec {
	position: fixed;
	left: 0;
	width: 100%;
	top: $height_header;
	background-color: $white;
	padding: 40px 0;
	z-index: 20;
	display: none;
	a {
		padding-bottom: 0.2em;
		background: -webkit-gradient(linear, left top, left bottom, from(#141a21), to(#141a21)) 0 100% / 0 1px no-repeat;
		background: linear-gradient(#141a21, #141a21) 0 100% / 0 1px no-repeat;
		-webkit-transition: background 0.3s;
		transition: background 0.3s;
		position: relative;
		color: $black;
		vertical-align: middle;
		display: inline;
		text-decoration: none;
		@include mq(pc) {
			&:hover {
				background-size: 100% 1px
			}
		}
		@include mq(md) {
			&:hover {
				background-size: 100% 1px
			}
		}
		&.blank {
			&:after {
				bottom: 0em;
				right: -1.5em;
			}
		}
	}
	a, span {
		color: $black;
	}

	.h_sec_box {
		display: flex;
		@include mq(sp) {
			display: block;
		}
		.h_sec_box1 {
			margin-right: 36px;
			@include mq(sp) {
				margin-right: 0px;
			}
		}
	}


	> dl {
		width: 100%;
		max-width: 960px;
		margin: 0 auto;
		display: flex;
		dt {
			width: pcnt(250, 960);
			display: flex;
			align-items: center;
			justify-content: center;
			a, span {
				font-size: 18px;
				letter-spacing: .04em;
			}
		}
		dd {
			width: pcnt(670, 960);
			border-left: #b3b3b3 1px solid;
			padding-left: 36px;
			display: flex;
			align-items: center;
			span {
				font-size: 15px;
				letter-spacing: -.01em;
			}
			a {
				font-size: 15px;
				letter-spacing: -.01em;
				&.pdf {
					&::after {
						bottom: .15em;
					}
				}
			}
			li {
				margin: 10px 0;
			}
		}
	}
}





/* フッター */
footer {
	background-color: $white;
}
.footlnk_all {
	@include w_center(1160);
	display: flex;
	flex-wrap: wrap;
	padding-top: 60px;
	@include mq(sp) {
		display: block;
		width: spvw(670);
		margin-left: auto;
		margin-right: auto;
		padding-top: 0;
	}
	.f_sp {
		display: none;
		@include mq(sp) {
			display: inline-block;
		}
	}
	.footlnk_box {
		&.footlnk_box_01 {
			order: 1;
			width: 100%;
			a {
				padding-bottom: 0.2em;
				background: -webkit-gradient(linear, left top, left bottom, from(#141a21), to(#141a21)) 0 100% / 0 1px no-repeat;
				background: linear-gradient(#141a21, #141a21) 0 100% / 0 1px no-repeat;
				-webkit-transition: background 0.3s;
				transition: background 0.3s;
				position: relative;
				color: $black;
				vertical-align: middle;
				display: inline;
				text-decoration: none;
				@include mq(pc) {
					&:hover {
						background-size: 100% 1px
					}
				}
				@include mq(sp) {
					padding: 0;
					display: block;
					line-height: 1.3em;
				}
				&.pdf {
					&::after {
						bottom: .15em;
					}
				}
				&.blank {
					&:after {
						bottom: 0em;
						right: -1.5em;
					}
					&.nex {
						&:after {
							@include mq(sp) {
								right: auto;
								left: 6em;
								bottom: -0.3em;
							}
						}
					}
				}
			}
			p {
				font-size: 14px;
				font-weight: 600;
				position: relative;
				padding-left: 1.2em;
				&::before {
					content: "・";
					position: absolute;
					top: .2em;
					left: 0;
					@include mq(sp) {
						display: none;
					}
				}
				i {
					display: none;
				}
				@include mq(sp) {
					font-size: spvw(36);
					padding-left: .8em;
					border-bottom: 1px solid #d4d4d4;
					padding: 1.25em 0 1.25em .8em;
					i {
						position: absolute;
						content: "";
						display: block;
						width: 100%;
						height: 1em;
						top: 1em;
						right: .8em;
						z-index: +1;
					}
					a {
						position: relative;
						&::after {
							position: absolute;
							content: "";
							width: .5em;
							height: .5em;
							border-right: 2px #3a3a3a solid;
							border-bottom: 2px #3a3a3a solid;
							transform: rotate(-45deg);
							right: .8em;
							top: .3em;
						}
					}
				}
				&.ttl_smap {
					margin-bottom: 1em;
					@include mq(sp) {
						margin-bottom: 0;
						a,span {
							position: relative;
							display: block;
							line-height: 1.3em;
							&::after {
								position: absolute;
								content: "";
								width: .5em;
								height: .5em;
								border-right: 2px #3a3a3a solid;
								border-bottom: 2px #3a3a3a solid;
								transform: rotate(45deg);
								right: .8em;
								top: 0;
							}
						}
						a {
							pointer-events: none;
						}
						&.opn {
							a,span {
								&::after {
									transform: rotate(-135deg);
									top: .4em;
								}
							}
						}
						span + i {
							width: 100%;
						}
					}
				}
				+ p.ttl_smap {
					margin-top: 2.4em;
					@include mq(sp) {
						margin-top: 0;
					}
				}
			}
			ul {
				@include mq(sp) {
					border-bottom: 1px solid #d4d4d4;
					display: none;
				}
				> li {
					margin: 0 0 1em 1.8em;
					font-size: 14px;
					font-weight: 400;
					@include mq(sp) {
						font-size: spvw(36);
						margin: 0;
						padding: .9em 0 .9em 1.8em;
						&:first-child {
							padding: 1.5em 0 .9em 1.8em;
						}
						&:last-child {
							padding: .9em 0 1.5em 1.8em;
						}
					}
				}
				+ p.ttl_smap {
					margin-top: 2.4em;
					@include mq(sp) {
						margin-top: 0;
					}
				}
			}
			.footlnk_smap {
				width: 100%;
				display: flex;
				@include mq(sp) {
					display: block;
				}
				.footlnk_smap_01 {
					width: pcnt(300, 1160);
					@include mq(sp) { width: 100%; }
				}
				.footlnk_smap_02 {
					width: pcnt(280, 1160);
					@include mq(sp) { width: 100%; }
				}
				.footlnk_smap_03 {
					width: pcnt(240, 1160);
					@include mq(sp) { width: 100%; }
				}
				.footlnk_smap_04 {
					width: pcnt(250, 1160);
					@include mq(sp) { width: 100%; }
				}
			}
		}
		&.footlnk_box_02 {
			order: 4;
			width: pcnt(740, 1160);
			border-top: 1px solid #d4d4d4;
			height: 54px;
			display: flex;
			align-items: center;
			@include mq(sp) {
				display: block;
				height: auto;
				width: 100%;
				border-top: none;
			}
			ul {
				display: flex;
				@include mq(sp) {
					display: block;
				}
				> li {
					padding-left: 1em;
					margin-left: 1em;
					border-left: 1px solid #d4d4d4;
					line-height: 1;
					display: block;
					@include mq(sp) {
						padding: 1.25em 0 1.25em .8em;
						border-left: none;
						margin: 0;
						font-size: spvw(36);
						line-height: 1.3em;
						border-bottom: 1px solid #d4d4d4;
					}
					&:first-child {
						padding-left: 0;
						margin-left: 0;
						border-left: none;
						@include mq(sp) {
							padding-left: .8em;
						}
					}
					> a {
						padding-bottom: 0.2em;
						background: -webkit-gradient(linear, left top, left bottom, from(#141a21), to(#141a21)) 0 100% / 0 1px no-repeat;
						background: linear-gradient(#141a21, #141a21) 0 100% / 0 1px no-repeat;
						-webkit-transition: background 0.3s;
						transition: background 0.3s;
						position: relative;
						color: $black;
						vertical-align: middle;
						text-decoration: none;
						margin: 0;
						display: block;
						font-size: 12px;
						@include mq(sp) {
							font-size: spvw(36);
							font-weight: 600;
							padding-bottom: 0;
							position: relative;
							&::after {
								position: absolute;
								content: "";
								width: .5em;
								height: .5em;
								border-right: 2px #3a3a3a solid;
								border-bottom: 2px #3a3a3a solid;
								transform: rotate(-45deg);
								right: .8em;
								top: .3em;
							}
						}
						@include mq(pc) {
							&:hover {
								background-size: 100% 1px
							}
						}
					}
				}
			}
		}
		&.footlnk_box_03 {
			order: 2;
			width: pcnt(740, 1160);
			border-top: 1px solid #d4d4d4;
			padding: 28px 0 14px;
			@include mq(sp) {
				display: block;
				width: 100%;
				padding: spvw(100) 0 spvw(50)s;
				border-top: none;
			}
			ul {
				&.row-icon {
					display: flex;
					> li {
						width: 28px;
						margin: 0 8px;
						@include mq(sp) {
							width: spvw(62);
							margin: 0 spvw(16);
						}
						a {
							display: block;
							width: 100%;
							img {
								width: 100%;
							}
						}
					}
				}
			}
			.footlnk_sns {
				display: flex;
				@include mq(sp) {
					display: block;
					width: 100%;
				}
				.footlnk_sns_txt {
					width: pcnt(430, 740);
					@include mq(sp) {
						display: block;
						width: 100%;
						text-align: center;
						margin-bottom: spvw(40);
					}
					.foot_sns_ttl {
						font-size: 16px;
						font-weight: 600;
						@include mq(sp) {
							font-size: spvw(28);
							margin-bottom: .5em;
						}
					}
                    .foot_sns_txt {
						font-size: 14px;
						font-weight: 400;
						letter-spacing: .05em;
						@include mq(sp) {
							font-size: spvw(24);
							letter-spacing: -.02em;
						}
					}
				}
				.footlnk_sns_lnk {
					width: pcnt(310, 740);
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					flex-wrap: wrap;
					@include mq(sp) {
						width: 100%;
					}
					p {
						font-size: 12px;
						margin-top: 1em;
						@include mq(sp) {
							font-size: spvw(24);
							margin-top: .5em;
						}
					}
				}
			}
		}
		&.footlnk_box_04 {
			text-align: right;
			order: 3;
			width: pcnt(420, 1160);
			display: flex;
			align-items: center;
			justify-content: right;
			text-align: right;
			@include mq(sp) {
				width: 100%;
				justify-content: center;
				text-align: center;
				height: spvw(180);
				border-top: 1px solid #d4d4d4;
			}
			.foot_jtblogo {
				width: 50%;
				height: 50px;
				background: transparent url(/common/img/logo_jtb2.svg) center center / auto no-repeat;
				a {
					display: block;
					height: 100%;
					@include clearText();
				}
				@include mq(sp) {
					width: spvw(520);
					height: 50px;
				}
			}
		}
		&.footlnk_box_05 {
			order: 5;
			width: pcnt(420, 1160);
			border-top: 1px solid #d4d4d4;
			height: 54px;
			display: flex;
			align-items: center;
			justify-content: right;
			text-align: right;
			@include mq(sp) {
				width: 100%;
				height: spvw(76);
				justify-content: center;
				text-align: center;
				font-size: spvw(20);
			}
		}
	}
	p {
		&.copywrite {
			font-size: 12px;
		}
	}
}
#totop {
	position: fixed;
	z-index: +2;
	cursor: pointer;
	border-radius: 100%;
	opacity: 0;
	pointer-events: none;
	transition: opacity .4s;
	background-image: linear-gradient(135deg, rgba(102, 102, 102, 1), rgba(160, 160, 160, 1));
	&.act {
		opacity: .6;
		pointer-events: all;
		@include mq(sp) {
			opacity: .8;
		}
	}
	> i {
		@include clearText;
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		transform: translateY(0);
		transition: transform .3s;
		&::before {
			position: absolute;
			content: "";
			transform: rotate(45deg);
			border-top: 2px solid $white;
			border-left: 2px solid $white;
			width: 20%;
			height: 20%;
			display: block;
			left: 37.5%;
			top: 32%;
		}
		&::after {
			position: absolute;
			content: "";
			background-color: $white;
			width: 2px;
			height: 40%;
			display: block;
			left: calc(50% - 1px);
			top: 32.5%;
		}
	}
	&:hover {
		opacity: 1;
		> i {
			@include mq(pc) {
				transform: translateY(-4px);
			}
		}
	}
	bottom: 40px;
	right: 40px;
	width: 60px;
	height: 60px;
	@include mq(sp) {
		bottom: 5vw;
		right: 5vw;
		width: 10vw;
		height: 10vw;
	}
}
#process {
	#totop {
		display: none;
	}
}

/* フッター 英語 */
html[lang=en] {
	.footlnk_all {
		.footlnk_box {
			&.footlnk_box_04 {
				.foot_jtblogo {
					background: transparent url(/common/img/logo_jtb2_en.svg) center center / auto no-repeat;
				}
			}
		}
	}
}



@import './abtest';