@charset "utf-8";


@keyframes body_load {
    0% { opacity: 1; }
    40% { opacity: 1; pointer-events: all; }
    99.9% { z-index: map-get($zindex, 'load'); }
    100% { opacity: 0; pointer-events: none; z-index: -1; }
}


html.is-fixed,
html.is-fixed body {
    height: 100%;
    overflow: hidden;
}

body {
    &::before {
        content: "";
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 10000;
        background-color: $white;
        opacity: 1;
        pointer-events: all;
        transition: opacity 1s .2s;
        animation: body_load 2.5s forwards;
    }
    // &.loaded {
    //     &::before {
    //         opacity: 0;
    //         pointer-events: none;
    //     }
    // }
}

main {
    padding-top: $height_header;
	@include mq(sp) {
		padding-top: spvw(134);
	}
}
// パンくず
.pankuzu_box {
    width: 100%;
    border-bottom: #ebebeb solid 1px;
    background-color: $white;
    nav {
        &.pankuzu {
            @include w_center(1160);
            padding: 10px 20px;
            box-sizing: border-box;
            @include mq(sp) {
                padding: spvw(10) 0;
                margin: 0 auto;
                width: 92%;
                // nav {
                //     &.pankuzu {
                //         ul {
                //             > li {
                //                 font-size: spvw(20);
                //             }
                //         }
                //     }
                // }
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                > li {
                    margin-right: .4em;
                    position: relative;
                    padding-left: 1.4em;
                    font-size: 12px;
                    line-height: 1.8em;
                    @include mq(sp) {
                        font-size: spvw(24);
                    }
                    &::before {
                        position: absolute;
                        content: "＞";
                        left: 0;
                        top: .1em;
                        color: $black;
                        font-size: .6em;
                    }
                    &:first-child {
                        padding-left: 0;
                        &::before {
                            display: none;
                        }
                    }
                    > a {
                        color: $black;
                    }
                }
            }
        }
    }
	// @include mq(sp) {
    //     padding: spvw(32) 0;
    //     margin: 0 auto;
    //     width: 92%;
    //     nav {
    //         &.pankuzu {
    //             ul {
    //                 > li {
    //                     font-size: spvw(20);
    //                 }
    //             }
    //         }
    //     }
    // }
}

// aタグにアイコン追加
a {
    &:hover {
    }
    &.pdf {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: 1.9em;
            height: 1.5em;
            right: -1.4em;
            bottom: -0.2em;
            display: inline-block;
            background: transparent url(/common/img/icon/ico_pdf.svg) right top / contain no-repeat;
        }
    }
    &.blank {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: 1.1em;
            height: 1.4em;
            right: -1.3em;
            bottom: -0.2em;
            display: inline-block;
            background: transparent url(/common/img/icon/ico_blank.svg) right top / contain no-repeat;
        }
    }
    
    &.cur {
        padding-bottom: 0.2em;
        background: -webkit-gradient(linear, left top, left bottom, from(#141a21), to(#141a21)) 0 100% / 0 1px no-repeat;
        background: linear-gradient(#141a21, #141a21) 0 100% / 0 1px no-repeat;
        -webkit-transition: background 0.3s;
        transition: background 0.3s;
        position: relative;
        color: $black;
        vertical-align: middle;
        display: inline;
        text-decoration: none;
        &.hd {
            background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ffffff)) 0 100% / 0 1px no-repeat;
            background: linear-gradient(#ffffff, #ffffff) 0 100% / 0 1px no-repeat;
            display: inline-block;
            color: $white !important;
            @include mq(sp) {
                color: $black !important;
            }
        }
        &:hover {
            background-size: 100% 1px
        }
    }
    
    &.cur2 {
        position: relative;
        display: inline-block;
        color: $black;
        vertical-align: middle;
    
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background: $black;
            transition: width .2s; 
        }
    
        &:hover {
            text-decoration: none;
            font-weight: bold;
            &:before { width: 100%; }
        }
    }
    
    &.cur3 {
        position: relative;
        display: inline-block;
        color: $black;
        vertical-align: middle;
        margin-bottom: .8em;
    
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background: $black;
            transition: width .2s; 
        }
    
        &:hover {
            text-decoration: none;
            &:before { width: 100%; }
        }
    }
}


.list_cate {
    @include flexbox(wrap, flex-start);//増減に対応できるように左詰めのカラム落ち仕様）
    @include w_center(1160);//両端にベタ付きになることは滅多にないと思うのでデフォでwidth指定しています
    margin-bottom: .5em;//必ず下に何らかの要素が来ると思うのでデフォで入れています
    font-size: 14px;

    li {
        margin-bottom: 1em;

        &:not(:last-child) {
            &:after {
                content: '｜';
                margin: 0 .5em;
                color: $gray1;
                font-size: 1.28em;
                vertical-align: middle;
            }
        }
    }

    a {
        position: relative;
        display: inline-block;
        color: $black;
        vertical-align: middle;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background: $black;
            transition: width .2s; 
        }

        &:hover, &.cur {
            text-decoration: none;
            font-weight: bold;
            &:before { width: 100%; }
        }
    }
}


/* select */
.select {
    @include flexbox(nowrap, flex-end);//selectは右寄せにすることが多いと思うのでflex-endをデフォにしています
    align-items: center;
    //width＆他要素とのmargin等はユニークに設定してください（前後左右の要素がかなりまちまちになると思うので）

    > span {
        flex-shrink: 0;
        margin-right: 1.5em;
        font-size: 14px;
        font-weight: bold;
        @include mq(sp) {
            margin-right: 2em;
        }
    }

    &_wrapper {
        position: relative;
        width: 270px;//デフォとして入れているので、適宜オーバーライドして良し
        border-bottom: 1px solid $black;
        font-size: 16px;//変更する際はselectにも同じfont-sizeを必ず入れること
        @include mq(sp) {
            width: 100%;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: .7em;
            width: 0.4em;
            height: 0.4em;
            border-left: 2px solid $black;
            border-bottom: 2px solid $black;
            transform: translate(-50%, -50%) rotateZ(315deg);
            transform-origin: 25% center;
            pointer-events: none;
        }
    }

    select {
        appearance: none;
        width: 100%;
        padding: .6em 1em;
        background: none;
        border: none;
        font-size: 16px;//変更する際は.select_wrapperにも同じfont-sizeを必ず入れること
        color: $gray2;
        @include mq(sp) {
            padding: 1em;
        }
    }
}

/* カテゴリーラベル */
.cate {
    display: inline-block;
    padding: .3em .8em;
    border: 1px solid $black;//黒はデフォ、個々のscssファイル内で↓の配列を使った出力で適宜class作成すること
    border-radius: 5em;
    font-size: 12px;
    line-height: 1;
    vertical-align: middle;
    @include mq(sp) {
        font-size: 10px;
    }
}
$cate_color: (
    'program': $ico_color_program,
    'report': $ico_color_report
);
@each $cate, $color in $cate_color {
    .cate_#{$cate} { border-color: $color; }
}

/* 戻るボタン */
.btn_back {
    font-size: 14px;
    color: $black;
    font-weight: bold;//boldをデフォにするかは赤坂さんに任せます

    &:before {
        content: '←';
        position: relative;
        left: 0;
        margin-right: 1em;
        transition: left .2s;
    }

    &:hover {
        text-decoration: none;
        &:before { left: -0.5em; }
    }
}

/* 中央寄せのbox（わざわざclass作る方が冗長になるところに使用する超汎用的なやつが欲しいなと） */
.w_center {
    @include w_center(1160);
}

/* 詳細タイトル */
.h1_detail {
    @include w_center(960);
    font-size: 26px;
    font-weight: bold;
    line-height: 1.7;
    @include mq(sp) {
        font-size: 18px;
    }
}

/* 詳細本文 */
.detail {
    max-width: 960px;
    margin: 0 auto;
    padding: 40px 50px 50px;
    background: $white;
    font-size: 16px;
    line-height: 2;
    @include mq(sp) {
        padding: 30px spvw(20);
        font-size: 14px;
        line-height: 1.85;
    }

    &_inner {
        max-width: 860px;
        margin: 0 auto;
    }

    //以降はグローバルに含めるか否かはおまかせ～
    .date {
        font-size: 14px;
        text-align: right;
        @include mq(sp) {
            font-size: 12px;
        }
    }

    .heading01 {
        margin: 3em 0 .5em;
        font-size: 18px;
        font-weight: bold;
        color: $red;
        @include mq(sp) {
            margin: 2em 0 .8em;
            font-size: 14px;
        }
    }

    figure {
        margin: 2.8em 0;
        text-align: center;
        @include mq(sp) {
            margin: 1.5em 0;
        }

        &:last-child { margin-bottom: 0; }

        img {
            max-width: 100%;
        }

        &.col2 {
            text-align: left;

            img {
                width: 47%;
                @include mq(sp) {
                    width: 100%;
                }

                &:nth-child(even) { margin-left: 6%; }
                &:nth-child(n+3) { margin-top: 5%; }
                &:nth-child(n+2) {
                    @include mq(sp) {
                        margin-left: 0;
                        margin-top: 5%;
                    }
                }
            }
        }
    }

    .img_float {
        @include clearfix;

        figure {
            float: left;
            width: 140px;
            margin: .5em 2em .5em 0;
            @include mq(sp) {
                width: spvw(252);
                margin: .3em 1.3em .3em 0;
            }

            img { width: 100%; }
        }
    }

    small {
        display: inline-block;
        font-size: 0.75em;
        @include mq(sp) {
            font-size: 0.85em;
            line-height: 2;
        }
    }
}


.h1_box {
    width: 100%;
    padding: 16px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #dcdcdc;
    background-color: $white;
    @include mq(pc) {
        padding: 16px calc(50vw - 570px);
    }
    @include mq(md) {
        padding: 16px 20px;
    }
	@include mq(sp) {
        padding: spvw(12) 0;
        margin: 0 auto;
        width: 100%;
    }
    h1 {
        font-size: 28px;
        @include mq(sp) {
            font-size: spvw(46);
            font-weight: 600;
            padding: 0 4%;
        }
    }
}

html[lang=en] * {
    word-break: break-word !important;
}

#newsrelease_top .news_txt a u,
#top .news_txt a u,
h1.h1_main > u {
    font-family: sans-serif !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    font-style: normal !important;
    text-decoration: none !important;
}
span.sansserif {
    font-family: sans-serif !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    font-style: inherit !important;
}
article {
    background-color: #f6f6f6;
}
body.ie {
}
