@charset "UTF-8";
/* 共通カルーセル用ドットアニメーション */
@keyframes dotsanime {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.clearText {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  letter-spacing: .1em;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

html {
  -webkit-text-size-adjust: 100%; }

body {
  line-height: 1.5;
  font-family: 'Noto Sans JP', sans-serif, 'hirakaku', 'YuGothic';
  font-weight: normal;
  color: #323232; }

ol, ul {
  list-style: none; }

select {
  outline: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%; }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

sup {
  vertical-align: top;
  font-size: 74%; }

button {
  color: #323232; }

::placeholder {
  color: #aaaaaa; }

@font-face {
  font-family: 'hirakaku';
  src: url("font/hirakaku_w3.woff") format("woff"), url("font/hirakaku_w3.woff2") format("woff2");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'hirakaku';
  src: url("font/hirakaku_w6.woff") format("woff"), url("font/hirakaku_w6.woff2") format("woff2");
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: 'YuGothic';
  src: url("font/YuGothicMedium.woff") format("woff"), url("font/YuGothicMedium.woff2") format("woff2");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'YuGothic';
  src: url("font/YuGothicBold.woff") format("woff"), url("font/YuGothicBold.woff2") format("woff2");
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: 'Roboto';
  src: url("font/Roboto-Medium.woff") format("woff"), url("font/Roboto-Medium.woff2") format("woff2");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("font/Roboto-Black.woff") format("woff"), url("font/Roboto-Black.woff2") format("woff2");
  font-style: normal;
  font-weight: bold; }

@keyframes body_load {
  0% {
    opacity: 1; }
  40% {
    opacity: 1;
    pointer-events: all; }
  99.9% {
    z-index: 10000; }
  100% {
    opacity: 0;
    pointer-events: none;
    z-index: -1; } }

html.is-fixed,
html.is-fixed body {
  height: 100%;
  overflow: hidden; }

body::before {
  content: "";
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10000;
  background-color: white;
  opacity: 1;
  pointer-events: all;
  transition: opacity 1s .2s;
  animation: body_load 2.5s forwards; }

main {
  padding-top: 103px; }
  @media screen and (max-width: 750px) {
    main {
      padding-top: calc((134 / 750) * 100vw); } }

.pankuzu_box {
  width: 100%;
  border-bottom: #ebebeb solid 1px;
  background-color: white; }
  .pankuzu_box nav.pankuzu {
    max-width: calc(1160*1px);
    width: calc(100*1%);
    margin-left: auto;
    margin-right: auto;
    padding: 10px 20px;
    box-sizing: border-box; }
    @media screen and (max-width: 750px) {
      .pankuzu_box nav.pankuzu {
        padding: calc((10 / 750) * 100vw) 0;
        margin: 0 auto;
        width: 92%; } }
    .pankuzu_box nav.pankuzu ul {
      display: flex;
      flex-wrap: wrap; }
      .pankuzu_box nav.pankuzu ul > li {
        margin-right: .4em;
        position: relative;
        padding-left: 1.4em;
        font-size: 12px;
        line-height: 1.8em; }
        @media screen and (max-width: 750px) {
          .pankuzu_box nav.pankuzu ul > li {
            font-size: calc((24 / 750) * 100vw); } }
        .pankuzu_box nav.pankuzu ul > li::before {
          position: absolute;
          content: "＞";
          left: 0;
          top: .1em;
          color: black;
          font-size: .6em; }
        .pankuzu_box nav.pankuzu ul > li:first-child {
          padding-left: 0; }
          .pankuzu_box nav.pankuzu ul > li:first-child::before {
            display: none; }
        .pankuzu_box nav.pankuzu ul > li > a {
          color: black; }

a.pdf {
  position: relative; }
  a.pdf:after {
    content: "";
    position: absolute;
    width: 1.9em;
    height: 1.5em;
    right: -1.4em;
    bottom: -0.2em;
    display: inline-block;
    background: transparent url(/common/img/icon/ico_pdf.svg) right top/contain no-repeat; }

a.blank {
  position: relative; }
  a.blank:after {
    content: "";
    position: absolute;
    width: 1.1em;
    height: 1.4em;
    right: -1.3em;
    bottom: -0.2em;
    display: inline-block;
    background: transparent url(/common/img/icon/ico_blank.svg) right top/contain no-repeat; }

a.cur {
  padding-bottom: 0.2em;
  background: -webkit-gradient(linear, left top, left bottom, from(#141a21), to(#141a21)) 0 100%/0 1px no-repeat;
  background: linear-gradient(#141a21, #141a21) 0 100%/0 1px no-repeat;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  position: relative;
  color: black;
  vertical-align: middle;
  display: inline;
  text-decoration: none; }
  a.cur.hd {
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ffffff)) 0 100%/0 1px no-repeat;
    background: linear-gradient(#ffffff, #ffffff) 0 100%/0 1px no-repeat;
    display: inline-block;
    color: white !important; }
    @media screen and (max-width: 750px) {
      a.cur.hd {
        color: black !important; } }
  a.cur:hover {
    background-size: 100% 1px; }

a.cur2 {
  position: relative;
  display: inline-block;
  color: black;
  vertical-align: middle; }
  a.cur2:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: black;
    transition: width .2s; }
  a.cur2:hover {
    text-decoration: none;
    font-weight: bold; }
    a.cur2:hover:before {
      width: 100%; }

a.cur3 {
  position: relative;
  display: inline-block;
  color: black;
  vertical-align: middle;
  margin-bottom: .8em; }
  a.cur3:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: black;
    transition: width .2s; }
  a.cur3:hover {
    text-decoration: none; }
    a.cur3:hover:before {
      width: 100%; }

.list_cate {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: calc(1160*1px);
  width: calc(100*1%);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: .5em;
  font-size: 14px; }
  .list_cate li {
    margin-bottom: 1em; }
    .list_cate li:not(:last-child):after {
      content: '｜';
      margin: 0 .5em;
      color: #c8c8c8;
      font-size: 1.28em;
      vertical-align: middle; }
  .list_cate a {
    position: relative;
    display: inline-block;
    color: black;
    vertical-align: middle; }
    .list_cate a:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background: black;
      transition: width .2s; }
    .list_cate a:hover, .list_cate a.cur {
      text-decoration: none;
      font-weight: bold; }
      .list_cate a:hover:before, .list_cate a.cur:before {
        width: 100%; }

/* select */
.select {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center; }
  .select > span {
    flex-shrink: 0;
    margin-right: 1.5em;
    font-size: 14px;
    font-weight: bold; }
    @media screen and (max-width: 750px) {
      .select > span {
        margin-right: 2em; } }
  .select_wrapper {
    position: relative;
    width: 270px;
    border-bottom: 1px solid black;
    font-size: 16px; }
    @media screen and (max-width: 750px) {
      .select_wrapper {
        width: 100%; } }
    .select_wrapper:after {
      content: '';
      position: absolute;
      top: 50%;
      right: .7em;
      width: 0.4em;
      height: 0.4em;
      border-left: 2px solid black;
      border-bottom: 2px solid black;
      transform: translate(-50%, -50%) rotateZ(315deg);
      transform-origin: 25% center;
      pointer-events: none; }
  .select select {
    appearance: none;
    width: 100%;
    padding: .6em 1em;
    background: none;
    border: none;
    font-size: 16px;
    color: #969696; }
    @media screen and (max-width: 750px) {
      .select select {
        padding: 1em; } }

/* カテゴリーラベル */
.cate {
  display: inline-block;
  padding: .3em .8em;
  border: 1px solid black;
  border-radius: 5em;
  font-size: 12px;
  line-height: 1;
  vertical-align: middle; }
  @media screen and (max-width: 750px) {
    .cate {
      font-size: 10px; } }

.cate_program {
  border-color: #f5a262; }

.cate_report {
  border-color: #4da9c0; }

/* 戻るボタン */
.btn_back {
  font-size: 14px;
  color: black;
  font-weight: bold; }
  .btn_back:before {
    content: '←';
    position: relative;
    left: 0;
    margin-right: 1em;
    transition: left .2s; }
  .btn_back:hover {
    text-decoration: none; }
    .btn_back:hover:before {
      left: -0.5em; }

/* 中央寄せのbox（わざわざclass作る方が冗長になるところに使用する超汎用的なやつが欲しいなと） */
.w_center {
  max-width: calc(1160*1px);
  width: calc(100*1%);
  margin-left: auto;
  margin-right: auto; }

/* 詳細タイトル */
.h1_detail {
  max-width: calc(960*1px);
  width: calc(100*1%);
  margin-left: auto;
  margin-right: auto;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.7; }
  @media screen and (max-width: 750px) {
    .h1_detail {
      font-size: 18px; } }

/* 詳細本文 */
.detail {
  max-width: 960px;
  margin: 0 auto;
  padding: 40px 50px 50px;
  background: white;
  font-size: 16px;
  line-height: 2; }
  @media screen and (max-width: 750px) {
    .detail {
      padding: 30px calc((20 / 750) * 100vw);
      font-size: 14px;
      line-height: 1.85; } }
  .detail_inner {
    max-width: 860px;
    margin: 0 auto; }
  .detail .date {
    font-size: 14px;
    text-align: right; }
    @media screen and (max-width: 750px) {
      .detail .date {
        font-size: 12px; } }
  .detail .heading01 {
    margin: 3em 0 .5em;
    font-size: 18px;
    font-weight: bold;
    color: #b70000; }
    @media screen and (max-width: 750px) {
      .detail .heading01 {
        margin: 2em 0 .8em;
        font-size: 14px; } }
  .detail figure {
    margin: 2.8em 0;
    text-align: center; }
    @media screen and (max-width: 750px) {
      .detail figure {
        margin: 1.5em 0; } }
    .detail figure:last-child {
      margin-bottom: 0; }
    .detail figure img {
      max-width: 100%; }
    .detail figure.col2 {
      text-align: left; }
      .detail figure.col2 img {
        width: 47%; }
        @media screen and (max-width: 750px) {
          .detail figure.col2 img {
            width: 100%; } }
        .detail figure.col2 img:nth-child(even) {
          margin-left: 6%; }
        .detail figure.col2 img:nth-child(n+3) {
          margin-top: 5%; }
        @media screen and (max-width: 750px) {
          .detail figure.col2 img:nth-child(n+2) {
            margin-left: 0;
            margin-top: 5%; } }
  .detail .img_float::after {
    content: "";
    display: block;
    clear: both; }
  .detail .img_float figure {
    float: left;
    width: 140px;
    margin: .5em 2em .5em 0; }
    @media screen and (max-width: 750px) {
      .detail .img_float figure {
        width: calc((252 / 750) * 100vw);
        margin: .3em 1.3em .3em 0; } }
    .detail .img_float figure img {
      width: 100%; }
  .detail small {
    display: inline-block;
    font-size: 0.75em; }
    @media screen and (max-width: 750px) {
      .detail small {
        font-size: 0.85em;
        line-height: 2; } }

.h1_box {
  width: 100%;
  padding: 16px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdcdc;
  background-color: white; }
  @media screen and (min-width: 750.1px) {
    .h1_box {
      padding: 16px calc(50vw - 570px); } }
  @media screen and (min-width: 750.1px) and (max-width: 1200px) {
    .h1_box {
      padding: 16px 20px; } }
  @media screen and (max-width: 750px) {
    .h1_box {
      padding: calc((12 / 750) * 100vw) 0;
      margin: 0 auto;
      width: 100%; } }
  .h1_box h1 {
    font-size: 28px; }
    @media screen and (max-width: 750px) {
      .h1_box h1 {
        font-size: calc((46 / 750) * 100vw);
        font-weight: 600;
        padding: 0 4%; } }

html[lang=en] * {
  word-break: break-word !important; }

#newsrelease_top .news_txt a u,
#top .news_txt a u,
h1.h1_main > u {
  font-family: sans-serif !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  font-style: normal !important;
  text-decoration: none !important; }

span.sansserif {
  font-family: sans-serif !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  font-style: inherit !important; }

article {
  background-color: #f6f6f6; }

.pc, .pc_only {
  display: block; }
  @media screen and (max-width: 750px) {
    .pc, .pc_only {
      display: none; } }

.sp, .sp_only {
  display: none; }
  @media screen and (max-width: 750px) {
    .sp, .sp_only {
      display: block; } }

.note01 {
  font-size: 12px; }
  @media screen and (max-width: 750px) {
    .note01 {
      font-size: calc((20 / 750) * 100vw); } }

.clearfix:after {
  display: block;
  content: "";
  clear: both; }

.red {
  color: red; }

.lthrough {
  text-decoration: line-through; }

.wb_break {
  word-break: break-all; }

.wb_keep {
  word-break: keep-all; }

.wb_word {
  word-break: break-word; }

.nowrap {
  white-space: nowrap; }

/* !anchor ------------------------------------------------------------ */
.anch_offset {
  margin-top: -75px !important;
  padding-top: 75px !important;
  display: block;
  height: 0;
  line-height: 0; }
  @media screen and (max-width: 750px) {
    .anch_offset {
      margin-top: -10vw !important;
      padding-top: 10vw !important; } }

/* !Inline Align ------------------------------------------------------------ */
.taL {
  text-align: left   !important; }

.taC {
  text-align: center !important; }

.taR {
  text-align: right  !important; }

.vaT {
  vertical-align: top    !important; }

.vaM {
  vertical-align: middle !important; }

.vaB {
  vertical-align: bottom !important; }

@media screen and (min-width: 750.1px) {
  .vaT_pc {
    vertical-align: top !important; } }

@media screen and (min-width: 750.1px) {
  .vaM_pc {
    vertical-align: middle !important; } }

@media screen and (min-width: 750.1px) {
  .vaB_pc {
    vertical-align: bottom !important; } }

.blockC {
  margin-right: auto;
  margin-left: auto;
  text-align: left; }

* html .blockC {
  margin-right: 0;
  margin-left: 0; }

.blockR {
  margin-left: auto;
  text-align: left; }

* html .blockR {
  margin-left: 0; }

/* for TimeMCE ------------------------------------------------------------ */
.ed__style__center {
  text-align: center;
  display: block;
  width: 100%; }

.ed__style__right {
  text-align: right;
  display: block;
  width: 100%; }

.ed__style__left {
  text-align: left;
  display: block;
  width: 100%; }

.ed__style__del {
  text-decoration: line-through; }

.ed__style__underline_solid {
  text-decoration: underline solid; }

.ed__style__underline_double {
  text-decoration: underline double; }

.ed__style__underline_dotted {
  text-decoration: underline dotted; }

.ed__style__underline_dashed {
  text-decoration: underline dashed; }

.ed__style__border {
  border: #323232 1px solid;
  padding: 0 .3em .15em .4em;
  margin: 0 .2em; }

.ed__style__borderbox {
  border: #323232 1px solid;
  padding: 0.2em .5em 0.2em .7em;
  display: block;
  text-align: left;
  margin: .5em 0; }

.ed__style__gradation__red {
  background-color: #be2a1c;
  background-image: linear-gradient(90deg, #be2a1c, #ec9d39);
  color: #fff;
  font-weight: bold;
  padding: .4em .5em .45em .8em;
  display: block;
  margin: 1.5em 0 .5em; }

.ed__style__gradation__blue {
  background-color: #002786;
  background-image: linear-gradient(90deg, #002786, #0193dc);
  color: #fff;
  font-weight: bold;
  padding: .4em .5em .45em .8em;
  display: block;
  margin: 1.5em 0 .5em; }

.ed__style__gradation__green {
  background-color: #204a32;
  background-image: linear-gradient(90deg, #204a32, #4e9901);
  color: #fff;
  font-weight: bold;
  padding: .4em .5em .45em .8em;
  display: block;
  margin: 1.5em 0 .5em; }

.head_all {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2000; }
  .head_all.imp {
    position: absolute; }
    .head_all.imp header {
      position: static; }
  .head_all + .h_bg {
    position: fixed;
    top: 103px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 103px);
    display: block;
    background-color: #000;
    opacity: 0;
    pointer-events: none;
    z-index: 18;
    transition: opacity .2s; }
    @media screen and (min-width: 750.1px) {
      .head_all + .h_bg.act {
        pointer-events: all;
        opacity: .3; } }
  .head_all .h_sinput {
    position: fixed;
    right: 0;
    width: 394px;
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
    top: 103px;
    background: linear-gradient(to right, #323232, #5e5e5e);
    padding: 15px 30px 15px 15px;
    z-index: 21;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s; }
    .head_all .h_sinput.act {
      opacity: 1;
      pointer-events: all; }
    @media screen and (max-width: 750px) {
      .head_all .h_sinput {
        border-radius: 0;
        width: 100%;
        padding: calc((30 / 750) * 100vw) calc((80 / 750) * 100vw);
        top: calc((134 / 750) * 100vw); } }
    .head_all .h_sinput label {
      position: relative;
      max-width: 650px;
      width: 100%; }
      .head_all .h_sinput label input {
        width: 100%;
        border-radius: 2.5em;
        padding: .8em 3em .8em 1.6em;
        font-size: 16px;
        box-sizing: border-box;
        border: none; }
        .head_all .h_sinput label input[type="text"] {
          border: none;
          outline: none;
          background-color: white !important; }
          .head_all .h_sinput label input[type="text"]:focus {
            border: none; }
        .head_all .h_sinput label input[type="submit"] {
          position: absolute;
          top: 50%;
          right: 1.25em;
          transform: translateY(-50%);
          width: 1.25em;
          height: 1.25em;
          padding: 0;
          text-indent: -9999px;
          cursor: pointer;
          background: transparent url(/common/img/icon/search_black.svg) center center/1.2em no-repeat; }
          .head_all .h_sinput label input[type="submit"]:focus {
            border: none;
            outline: none; }
        @media screen and (max-width: 750px) {
          .head_all .h_sinput label input {
            font-size: calc((32 / 750) * 100vw); }
            .head_all .h_sinput label input[type="submit"] {
              background: transparent url(/common/img/icon/search_black.svg) center center/cover no-repeat !important; } }
  .head_all header {
    width: 100%;
    position: fixed;
    height: 103px;
    z-index: 20; }
    .head_all header:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 103px;
      z-index: +1;
      top: 0;
      left: 0;
      opacity: 1;
      background: transparent url(/common/img/bg_header.png) center center/100% 100% no-repeat;
      transition: opacity .2s; }
      @media screen and (max-width: 750px) {
        .head_all header:after {
          height: calc((134 / 750) * 100vw); } }
    @media screen and (max-width: 750px) {
      .head_all header {
        background: transparent url(/common/img/bg_header_sp.png) center center/100% 100% no-repeat;
        height: calc((134 / 750) * 100vw); } }
    .head_all header h1 {
      width: 112px;
      position: absolute;
      top: 23px;
      left: 30px;
      z-index: +3; }
      @media screen and (max-width: 750px) {
        .head_all header h1 {
          width: calc((158 / 750) * 100vw);
          top: calc((26 / 750) * 100vw);
          left: calc((28 / 750) * 100vw); } }
    .head_all header .h_menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      position: relative;
      z-index: +2; }
      .head_all header .h_menu a, .head_all header .h_menu span {
        color: white; }
      @media screen and (max-width: 750px) {
        .head_all header .h_menu {
          display: none;
          position: fixed;
          left: 0;
          top: calc((134 / 750) * 100vw);
          background-color: white;
          width: 100%;
          overflow-y: auto;
          max-height: calc(100vh - ((134 / 750) * 100vw)); }
          .head_all header .h_menu a, .head_all header .h_menu span {
            color: black;
            line-height: 1.3em;
            display: block; }
          .head_all header .h_menu dl dt span {
            display: none; } }
      .head_all header .h_menu::after {
        content: "";
        width: 100%;
        order: 4; }
      .head_all header .h_menu .h_menu01 {
        order: 1;
        margin-top: 10px; }
        .head_all header .h_menu .h_menu01 > ul {
          display: flex; }
          .head_all header .h_menu .h_menu01 > ul li {
            margin: 0 .5em; }
            .head_all header .h_menu .h_menu01 > ul li > a {
              font-size: 12px;
              color: white;
              border: 1px solid white;
              border-radius: 1.5em;
              padding: .4em 1.4em; }
            @media screen and (min-width: 750.1px) {
              .head_all header .h_menu .h_menu01 > ul li > a {
                transition: background-color .3s; }
                .head_all header .h_menu .h_menu01 > ul li > a:hover {
                  text-decoration: none;
                  background-color: white; }
                  .head_all header .h_menu .h_menu01 > ul li > a:hover span {
                    color: #d51d1d; } }
            @media screen and (min-width: 750.1px) and (max-width: 1200px) {
              .head_all header .h_menu .h_menu01 > ul li {
                margin: 0 calc(0.00976 * 100vw - 5.3172px); }
                .head_all header .h_menu .h_menu01 > ul li > a {
                  font-size: calc(0.00488 * 100vw + 6.3414px);
                  padding: 0.4em calc(0.03122 * 100vw - 19.41504px); }
                  .head_all header .h_menu .h_menu01 > ul li > a > span {
                    letter-spacing: calc(0.003171 * 100vw - 2.47809px); } }
            @media screen and (max-width: 750px) {
              .head_all header .h_menu .h_menu01 > ul li {
                margin: 0;
                height: calc((110 / 750) * 100vw); }
                .head_all header .h_menu .h_menu01 > ul li > a {
                  border-radius: 0;
                  padding: .4em 1.4em;
                  font-size: calc((30 / 750) * 100vw);
                  display: flex;
                  height: 100%;
                  color: white;
                  align-items: center; }
                  .head_all header .h_menu .h_menu01 > ul li > a::after {
                    position: absolute;
                    content: "";
                    width: 2.5em;
                    height: .8em;
                    opacity: 1;
                    right: 1.2em;
                    background: transparent url(/top/img/arrow.svg) right center/auto no-repeat; }
                  .head_all header .h_menu .h_menu01 > ul li > a span {
                    color: white; }
                .head_all header .h_menu .h_menu01 > ul li.h_ho {
                  background: linear-gradient(to right, #454545, #a4a4a4); }
                .head_all header .h_menu .h_menu01 > ul li.h_ko {
                  background: linear-gradient(to right, #d01818, #ec652a); }
                .head_all header .h_menu .h_menu01 > ul li.h_ji {
                  background: linear-gradient(to right, #183187, #18afe3); } }
          @media screen and (max-width: 750px) {
            .head_all header .h_menu .h_menu01 > ul {
              display: block; } }
      .head_all header .h_menu .h_menu02 {
        order: 5;
        margin-top: 20px;
        margin-right: 60px; }
        @media screen and (max-width: 750px) {
          .head_all header .h_menu .h_menu02 {
            margin: calc((40 / 750) * 100vw) auto 0; } }
        .head_all header .h_menu .h_menu02 > ul {
          display: flex; }
          @media screen and (max-width: 750px) {
            .head_all header .h_menu .h_menu02 > ul {
              display: block;
              margin: 0 auto;
              width: calc((670 / 750) * 100vw); } }
          .head_all header .h_menu .h_menu02 > ul > li {
            font-size: 16px;
            padding: .2em .6em;
            margin: 0 .5em; }
            .head_all header .h_menu .h_menu02 > ul > li > a {
              vertical-align: middle;
              display: inline-block;
              text-decoration: none;
              padding-bottom: 0.2em; }
              @media screen and (max-width: 750px) {
                .head_all header .h_menu .h_menu02 > ul > li > a {
                  display: block; } }
            @media screen and (min-width: 750.1px) {
              .head_all header .h_menu .h_menu02 > ul > li.h_ourstory, .head_all header .h_menu .h_menu02 > ul > li.h_business, .head_all header .h_menu .h_menu02 > ul > li.h_sustainability, .head_all header .h_menu .h_menu02 > ul > li.h_ownedmedia {
                position: relative;
                cursor: pointer; }
                .head_all header .h_menu .h_menu02 > ul > li.h_ourstory::after, .head_all header .h_menu .h_menu02 > ul > li.h_business::after, .head_all header .h_menu .h_menu02 > ul > li.h_sustainability::after, .head_all header .h_menu .h_menu02 > ul > li.h_ownedmedia::after {
                  content: "";
                  position: absolute;
                  width: 0%;
                  height: 4px;
                  background-color: white;
                  bottom: -.7em;
                  left: 0;
                  opacity: 0;
                  transition: width .3s, opacity .1s; }
                .head_all header .h_menu .h_menu02 > ul > li.h_ourstory.act::after, .head_all header .h_menu .h_menu02 > ul > li.h_ourstory:hover::after, .head_all header .h_menu .h_menu02 > ul > li.h_business.act::after, .head_all header .h_menu .h_menu02 > ul > li.h_business:hover::after, .head_all header .h_menu .h_menu02 > ul > li.h_sustainability.act::after, .head_all header .h_menu .h_menu02 > ul > li.h_sustainability:hover::after, .head_all header .h_menu .h_menu02 > ul > li.h_ownedmedia.act::after, .head_all header .h_menu .h_menu02 > ul > li.h_ownedmedia:hover::after {
                  width: 100%;
                  opacity: 1; } }
            @media screen and (min-width: 750.1px) and (max-width: 1200px) {
              .head_all header .h_menu .h_menu02 > ul > li {
                font-size: calc(0.00488 * 100vw + 10.3414px); }
                .head_all header .h_menu .h_menu02 > ul > li.h_ourstory::after, .head_all header .h_menu .h_menu02 > ul > li.h_business::after, .head_all header .h_menu .h_menu02 > ul > li.h_sustainability::after, .head_all header .h_menu .h_menu02 > ul > li.h_ownedmedia::after {
                  bottom: calc(0.025 * 100vw - 40px); }
                .head_all header .h_menu .h_menu02 > ul > li > a > span {
                  letter-spacing: calc(0.003171 * 100vw - 2.47809px); } }
            @media screen and (max-width: 750px) {
              .head_all header .h_menu .h_menu02 > ul > li {
                font-size: calc((36 / 750) * 100vw);
                padding: 1.25em 0 1.25em .5em;
                font-weight: 600;
                margin: 0;
                border-bottom: 1px solid #d4d4d4; }
                .head_all header .h_menu .h_menu02 > ul > li > a {
                  position: relative; }
                  .head_all header .h_menu .h_menu02 > ul > li > a::after {
                    position: absolute;
                    content: "";
                    width: .5em;
                    height: .5em;
                    border-right: 2px #3a3a3a solid;
                    border-bottom: 2px #3a3a3a solid;
                    transform: rotate(-45deg);
                    right: .8em;
                    top: .3em; }
                .head_all header .h_menu .h_menu02 > ul > li > span {
                  position: relative; }
                  .head_all header .h_menu .h_menu02 > ul > li > span::after {
                    position: absolute;
                    content: "";
                    width: .5em;
                    height: .5em;
                    border-right: 2px #3a3a3a solid;
                    border-bottom: 2px #3a3a3a solid;
                    transform: rotate(45deg);
                    right: .8em;
                    top: .3em; }
                .head_all header .h_menu .h_menu02 > ul > li.opn span::after {
                  transform: rotate(-135deg);
                  top: .4em; }
                .head_all header .h_menu .h_menu02 > ul > li dl dt a {
                  font-weight: 400;
                  padding: .7em 0 .7em 1em; }
                .head_all header .h_menu .h_menu02 > ul > li dl dd ul > li a {
                  font-weight: 400;
                  padding: .7em 0 .7em 1em; } }
        @media screen and (max-width: 750px) {
          .head_all header .h_menu .h_menu02 .h_sec_box2 ul li a.blank.nex:after {
            right: auto;
            left: 7em;
            bottom: .5em; } }
      .head_all header .h_menu .h_menu03 {
        order: 2;
        margin-top: 10px;
        margin-left: 12px; }
        @media screen and (min-width: 750.1px) and (max-width: 1200px) {
          .head_all header .h_menu .h_menu03 {
            margin-left: calc(0.02439 * 100vw - 16.293px); } }
        @media screen and (max-width: 750px) {
          .head_all header .h_menu .h_menu03 {
            margin: 0 auto; } }
        .head_all header .h_menu .h_menu03 > ul {
          display: flex; }
          @media screen and (max-width: 750px) {
            .head_all header .h_menu .h_menu03 > ul {
              display: block;
              width: calc((670 / 750) * 100vw);
              margin: 0 auto; } }
          .head_all header .h_menu .h_menu03 > ul > li {
            font-size: 12px;
            padding: .4em .4em 0;
            margin: 0 .7em; }
            @media screen and (min-width: 750.1px) {
              .head_all header .h_menu .h_menu03 > ul > li.h_company, .head_all header .h_menu .h_menu03 > ul > li.h_recruit {
                position: relative;
                cursor: pointer; }
                .head_all header .h_menu .h_menu03 > ul > li.h_company::after, .head_all header .h_menu .h_menu03 > ul > li.h_recruit::after {
                  content: "";
                  position: absolute;
                  width: 0%;
                  height: 1px;
                  background-color: white;
                  bottom: 0em;
                  left: 0;
                  opacity: 0;
                  transition: width .3s, opacity .1s; }
                .head_all header .h_menu .h_menu03 > ul > li.h_company.act::after, .head_all header .h_menu .h_menu03 > ul > li.h_company:hover::after, .head_all header .h_menu .h_menu03 > ul > li.h_recruit.act::after, .head_all header .h_menu .h_menu03 > ul > li.h_recruit:hover::after {
                  width: 100%;
                  opacity: 1; } }
            @media screen and (min-width: 750.1px) and (max-width: 1200px) {
              .head_all header .h_menu .h_menu03 > ul > li {
                font-size: calc(0.00488 * 100vw + 6.3414px);
                padding: 0.4em calc(0.03122 * 100vw - 19.41504px);
                margin: 0 calc(0.00976 * 100vw - 5.3172px);
                padding: 0.4em calc(0.00585 * 100vw - 1.99032px) 0;
                margin: 0 calc(0.01756 * 100vw - 11.97096px); }
                .head_all header .h_menu .h_menu03 > ul > li.h_company, .head_all header .h_menu .h_menu03 > ul > li.h_recruit {
                  position: relative; }
                  .head_all header .h_menu .h_menu03 > ul > li.h_company::after, .head_all header .h_menu .h_menu03 > ul > li.h_recruit::after {
                    content: "";
                    position: absolute;
                    width: 0%;
                    height: 1px;
                    background-color: white;
                    bottom: 0em;
                    left: 0;
                    opacity: 0;
                    transition: width .3s, opacity .1s; }
                  .head_all header .h_menu .h_menu03 > ul > li.h_company.act::after, .head_all header .h_menu .h_menu03 > ul > li.h_recruit.act::after {
                    width: 100%;
                    opacity: 1; }
                .head_all header .h_menu .h_menu03 > ul > li > a > span {
                  letter-spacing: calc(0.003171 * 100vw - 2.47809px); } }
            @media screen and (max-width: 750px) {
              .head_all header .h_menu .h_menu03 > ul > li {
                font-size: calc((36 / 750) * 100vw);
                padding: 1.25em 0 1.25em .5em;
                margin: 0;
                font-weight: 600;
                border-bottom: 1px solid #d4d4d4; }
                .head_all header .h_menu .h_menu03 > ul > li > a {
                  position: relative; }
                  .head_all header .h_menu .h_menu03 > ul > li > a::after {
                    position: absolute;
                    content: "";
                    width: .5em;
                    height: .5em;
                    border-right: 2px #3a3a3a solid;
                    border-bottom: 2px #3a3a3a solid;
                    transform: rotate(-45deg);
                    right: .8em;
                    top: .3em; }
                .head_all header .h_menu .h_menu03 > ul > li > span {
                  position: relative; }
                  .head_all header .h_menu .h_menu03 > ul > li > span::after {
                    position: absolute;
                    content: "";
                    width: .5em;
                    height: .5em;
                    border-right: 2px #3a3a3a solid;
                    border-bottom: 2px #3a3a3a solid;
                    transform: rotate(45deg);
                    right: .8em;
                    top: .3em; }
                .head_all header .h_menu .h_menu03 > ul > li.opn span::after {
                  transform: rotate(-135deg);
                  top: .4em; }
                .head_all header .h_menu .h_menu03 > ul > li dl dt a {
                  font-weight: 400;
                  padding: .7em 0 .7em 1em; }
                .head_all header .h_menu .h_menu03 > ul > li dl dd ul > li a {
                  font-weight: 400;
                  padding: .7em 0 .7em 1em; } }
      .head_all header .h_menu .h_menu04 {
        order: 3;
        margin-top: 10px; }
        @media screen and (max-width: 750px) {
          .head_all header .h_menu .h_menu04 {
            display: flex;
            justify-content: center;
            padding-bottom: calc((195 / 750) * 100vw); } }
        .head_all header .h_menu .h_menu04 > ul {
          display: flex; }
          .head_all header .h_menu .h_menu04 > ul li {
            font-size: 12px;
            padding: .4em .6em;
            margin: 0 1.5em 0 .5em;
            text-underline-offset: .5em;
            color: white; }
            .head_all header .h_menu .h_menu04 > ul li:first-child {
              text-decoration: underline; }
            .head_all header .h_menu .h_menu04 > ul li:last-child {
              position: relative; }
              .head_all header .h_menu .h_menu04 > ul li:last-child::before {
                position: absolute;
                content: "";
                left: -1em;
                top: .6em;
                height: 1.1em;
                width: 1px;
                background-color: white;
                transform: rotate(10deg); }
            @media screen and (min-width: 750.1px) and (max-width: 1200px) {
              .head_all header .h_menu .h_menu04 > ul li {
                font-size: calc(0.00488 * 100vw + 6.3414px);
                margin: 0 calc(0.03171 * 100vw - 18.7809px) 0 0.5em; }
                .head_all header .h_menu .h_menu04 > ul li > a > span {
                  letter-spacing: calc(0.003171 * 100vw - 2.47809px); } }
            @media screen and (max-width: 750px) {
              .head_all header .h_menu .h_menu04 > ul li {
                color: black;
                font-size: calc((30 / 750) * 100vw); }
                .head_all header .h_menu .h_menu04 > ul li:last-child::before {
                  background-color: black; } }

/* ヘッダー 英語 */
html[lang=en] .head_all header .h_menu .h_menu04 > ul li:first-child {
  text-decoration: none; }

html[lang=en] .head_all header .h_menu .h_menu04 > ul li:last-child {
  text-decoration: underline; }

@media screen and (min-width: 750.1px) {
  html[lang=en] .head_all header .h_menu .h_menu02 > ul > li.h_ourstory::after, html[lang=en] .head_all header .h_menu .h_menu02 > ul > li.h_business::after, html[lang=en] .head_all header .h_menu .h_menu02 > ul > li.h_sustainability::after {
    bottom: -.95em; } }

@media screen and (min-width: 750.1px) and (max-width: 1200px) {
  html[lang=en] .head_all header .h_menu .h_menu02 > ul > li.h_ourstory::after, html[lang=en] .head_all header .h_menu .h_menu02 > ul > li.h_business::after, html[lang=en] .head_all header .h_menu .h_menu02 > ul > li.h_sustainability::after {
    bottom: calc(0.022 * 100vw - 40px); } }

#top header:after {
  opacity: 0; }

#top header:hover:after, #top header.kvact:after, #top header.act:after {
  opacity: 1; }

#newsrelease_top main .head_all {
  top: 0;
  position: absolute; }

.h_search {
  position: absolute;
  display: block;
  top: 57px;
  right: 20px;
  width: 37px;
  height: 37px;
  cursor: pointer;
  background: transparent url(/common/img/icon/search.svg) center center no-repeat;
  background-size: 50%;
  z-index: 30; }
  .h_search::before {
    width: 2px;
    height: 50%;
    background-color: white;
    position: absolute;
    content: "";
    transform: rotate(45deg);
    left: 1.1em;
    top: .5em;
    opacity: 0;
    transition: opacity .2s; }
  .h_search::after {
    width: 2px;
    height: 50%;
    background-color: white;
    position: absolute;
    content: "";
    transform: rotate(-45deg);
    left: 1.1em;
    top: .5em;
    opacity: 0;
    transition: opacity .2s; }
  .h_search.act {
    background: transparent; }
    .h_search.act::before, .h_search.act::after {
      opacity: 1; }
  @media screen and (min-width: 750.1px) and (max-width: 1200px) {
    .h_search {
      top: calc(0.00732 * 100vw + 49.5121px);
      width: calc(0.02439 * 100vw + 8.707px);
      height: calc(0.02439 * 100vw + 8.707px); } }
  @media screen and (max-width: 750px) {
    .h_search {
      background-size: 100%;
      top: calc((40 / 750) * 100vw);
      right: calc((195 / 750) * 100vw);
      width: calc((52 / 750) * 100vw);
      height: calc((52 / 750) * 100vw);
      margin: 0; }
      .h_search::before, .h_search::after {
        height: 100%;
        left: 0.8em;
        top: 0em; } }

.h_humb {
  display: none; }
  @media screen and (max-width: 750px) {
    .h_humb {
      position: absolute;
      top: calc((40 / 750) * 100vw);
      right: calc((40 / 750) * 100vw);
      display: block;
      z-index: 30;
      pointer-events: all; }
      .h_humb > i {
        height: calc((4 / 750) * 100vw);
        width: calc((93 / 750) * 100vw);
        display: block;
        background-color: white;
        opacity: 1;
        transition: transform .2s; }
        .h_humb > i:nth-child(1) {
          transform-origin: right top; }
        .h_humb > i:nth-child(2) {
          margin-top: calc((18 / 750) * 100vw);
          transform-origin: right; }
        .h_humb > i:nth-child(3) {
          margin-top: calc((18 / 750) * 100vw);
          transform-origin: right bottom; }
      .h_humb.act > i:nth-child(1) {
        width: calc((100 / 750) * 100vw);
        transform: rotate(-26deg); }
      .h_humb.act > i:nth-child(2) {
        opacity: 0; }
      .h_humb.act > i:nth-child(3) {
        width: calc((100 / 750) * 100vw);
        transform: rotate(26deg); }
      .h_humb.pointnone {
        pointer-events: none; } }

.h_sec {
  position: fixed;
  left: 0;
  width: 100%;
  top: 103px;
  background-color: white;
  padding: 40px 0;
  z-index: 20;
  display: none; }
  .h_sec a {
    padding-bottom: 0.2em;
    background: -webkit-gradient(linear, left top, left bottom, from(#141a21), to(#141a21)) 0 100%/0 1px no-repeat;
    background: linear-gradient(#141a21, #141a21) 0 100%/0 1px no-repeat;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    position: relative;
    color: black;
    vertical-align: middle;
    display: inline;
    text-decoration: none; }
    @media screen and (min-width: 750.1px) {
      .h_sec a:hover {
        background-size: 100% 1px; } }
    @media screen and (min-width: 750.1px) and (max-width: 1200px) {
      .h_sec a:hover {
        background-size: 100% 1px; } }
    .h_sec a.blank:after {
      bottom: 0em;
      right: -1.5em; }
  .h_sec a, .h_sec span {
    color: black; }
  .h_sec .h_sec_box {
    display: flex; }
    @media screen and (max-width: 750px) {
      .h_sec .h_sec_box {
        display: block; } }
    .h_sec .h_sec_box .h_sec_box1 {
      margin-right: 36px; }
      @media screen and (max-width: 750px) {
        .h_sec .h_sec_box .h_sec_box1 {
          margin-right: 0px; } }
  .h_sec > dl {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    display: flex; }
    .h_sec > dl dt {
      width: calc((250 / 960) * 100%);
      display: flex;
      align-items: center;
      justify-content: center; }
      .h_sec > dl dt a, .h_sec > dl dt span {
        font-size: 18px;
        letter-spacing: .04em; }
    .h_sec > dl dd {
      width: calc((670 / 960) * 100%);
      border-left: #b3b3b3 1px solid;
      padding-left: 36px;
      display: flex;
      align-items: center; }
      .h_sec > dl dd span {
        font-size: 15px;
        letter-spacing: -.01em; }
      .h_sec > dl dd a {
        font-size: 15px;
        letter-spacing: -.01em; }
        .h_sec > dl dd a.pdf::after {
          bottom: .15em; }
      .h_sec > dl dd li {
        margin: 10px 0; }

/* フッター */
footer {
  background-color: white; }

.footlnk_all {
  max-width: calc(1160*1px);
  width: calc(100*1%);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px; }
  @media screen and (max-width: 750px) {
    .footlnk_all {
      display: block;
      width: calc((670 / 750) * 100vw);
      margin-left: auto;
      margin-right: auto;
      padding-top: 0; } }
  .footlnk_all .f_sp {
    display: none; }
    @media screen and (max-width: 750px) {
      .footlnk_all .f_sp {
        display: inline-block; } }
  .footlnk_all .footlnk_box.footlnk_box_01 {
    order: 1;
    width: 100%; }
    .footlnk_all .footlnk_box.footlnk_box_01 a {
      padding-bottom: 0.2em;
      background: -webkit-gradient(linear, left top, left bottom, from(#141a21), to(#141a21)) 0 100%/0 1px no-repeat;
      background: linear-gradient(#141a21, #141a21) 0 100%/0 1px no-repeat;
      -webkit-transition: background 0.3s;
      transition: background 0.3s;
      position: relative;
      color: black;
      vertical-align: middle;
      display: inline;
      text-decoration: none; }
      @media screen and (min-width: 750.1px) {
        .footlnk_all .footlnk_box.footlnk_box_01 a:hover {
          background-size: 100% 1px; } }
      @media screen and (max-width: 750px) {
        .footlnk_all .footlnk_box.footlnk_box_01 a {
          padding: 0;
          display: block;
          line-height: 1.3em; } }
      .footlnk_all .footlnk_box.footlnk_box_01 a.pdf::after {
        bottom: .15em; }
      .footlnk_all .footlnk_box.footlnk_box_01 a.blank:after {
        bottom: 0em;
        right: -1.5em; }
      @media screen and (max-width: 750px) {
        .footlnk_all .footlnk_box.footlnk_box_01 a.blank.nex:after {
          right: auto;
          left: 6em;
          bottom: -0.3em; } }
    .footlnk_all .footlnk_box.footlnk_box_01 p {
      font-size: 14px;
      font-weight: 600;
      position: relative;
      padding-left: 1.2em; }
      .footlnk_all .footlnk_box.footlnk_box_01 p::before {
        content: "・";
        position: absolute;
        top: .2em;
        left: 0; }
        @media screen and (max-width: 750px) {
          .footlnk_all .footlnk_box.footlnk_box_01 p::before {
            display: none; } }
      .footlnk_all .footlnk_box.footlnk_box_01 p i {
        display: none; }
      @media screen and (max-width: 750px) {
        .footlnk_all .footlnk_box.footlnk_box_01 p {
          font-size: calc((36 / 750) * 100vw);
          padding-left: .8em;
          border-bottom: 1px solid #d4d4d4;
          padding: 1.25em 0 1.25em .8em; }
          .footlnk_all .footlnk_box.footlnk_box_01 p i {
            position: absolute;
            content: "";
            display: block;
            width: 100%;
            height: 1em;
            top: 1em;
            right: .8em;
            z-index: +1; }
          .footlnk_all .footlnk_box.footlnk_box_01 p a {
            position: relative; }
            .footlnk_all .footlnk_box.footlnk_box_01 p a::after {
              position: absolute;
              content: "";
              width: .5em;
              height: .5em;
              border-right: 2px #3a3a3a solid;
              border-bottom: 2px #3a3a3a solid;
              transform: rotate(-45deg);
              right: .8em;
              top: .3em; } }
      .footlnk_all .footlnk_box.footlnk_box_01 p.ttl_smap {
        margin-bottom: 1em; }
        @media screen and (max-width: 750px) {
          .footlnk_all .footlnk_box.footlnk_box_01 p.ttl_smap {
            margin-bottom: 0; }
            .footlnk_all .footlnk_box.footlnk_box_01 p.ttl_smap a, .footlnk_all .footlnk_box.footlnk_box_01 p.ttl_smap span {
              position: relative;
              display: block;
              line-height: 1.3em; }
              .footlnk_all .footlnk_box.footlnk_box_01 p.ttl_smap a::after, .footlnk_all .footlnk_box.footlnk_box_01 p.ttl_smap span::after {
                position: absolute;
                content: "";
                width: .5em;
                height: .5em;
                border-right: 2px #3a3a3a solid;
                border-bottom: 2px #3a3a3a solid;
                transform: rotate(45deg);
                right: .8em;
                top: 0; }
            .footlnk_all .footlnk_box.footlnk_box_01 p.ttl_smap a {
              pointer-events: none; }
            .footlnk_all .footlnk_box.footlnk_box_01 p.ttl_smap.opn a::after, .footlnk_all .footlnk_box.footlnk_box_01 p.ttl_smap.opn span::after {
              transform: rotate(-135deg);
              top: .4em; }
            .footlnk_all .footlnk_box.footlnk_box_01 p.ttl_smap span + i {
              width: 100%; } }
      .footlnk_all .footlnk_box.footlnk_box_01 p + p.ttl_smap {
        margin-top: 2.4em; }
        @media screen and (max-width: 750px) {
          .footlnk_all .footlnk_box.footlnk_box_01 p + p.ttl_smap {
            margin-top: 0; } }
    @media screen and (max-width: 750px) {
      .footlnk_all .footlnk_box.footlnk_box_01 ul {
        border-bottom: 1px solid #d4d4d4;
        display: none; } }
    .footlnk_all .footlnk_box.footlnk_box_01 ul > li {
      margin: 0 0 1em 1.8em;
      font-size: 14px;
      font-weight: 400; }
      @media screen and (max-width: 750px) {
        .footlnk_all .footlnk_box.footlnk_box_01 ul > li {
          font-size: calc((36 / 750) * 100vw);
          margin: 0;
          padding: .9em 0 .9em 1.8em; }
          .footlnk_all .footlnk_box.footlnk_box_01 ul > li:first-child {
            padding: 1.5em 0 .9em 1.8em; }
          .footlnk_all .footlnk_box.footlnk_box_01 ul > li:last-child {
            padding: .9em 0 1.5em 1.8em; } }
    .footlnk_all .footlnk_box.footlnk_box_01 ul + p.ttl_smap {
      margin-top: 2.4em; }
      @media screen and (max-width: 750px) {
        .footlnk_all .footlnk_box.footlnk_box_01 ul + p.ttl_smap {
          margin-top: 0; } }
    .footlnk_all .footlnk_box.footlnk_box_01 .footlnk_smap {
      width: 100%;
      display: flex; }
      @media screen and (max-width: 750px) {
        .footlnk_all .footlnk_box.footlnk_box_01 .footlnk_smap {
          display: block; } }
      .footlnk_all .footlnk_box.footlnk_box_01 .footlnk_smap .footlnk_smap_01 {
        width: calc((300 / 1160) * 100%); }
        @media screen and (max-width: 750px) {
          .footlnk_all .footlnk_box.footlnk_box_01 .footlnk_smap .footlnk_smap_01 {
            width: 100%; } }
      .footlnk_all .footlnk_box.footlnk_box_01 .footlnk_smap .footlnk_smap_02 {
        width: calc((280 / 1160) * 100%); }
        @media screen and (max-width: 750px) {
          .footlnk_all .footlnk_box.footlnk_box_01 .footlnk_smap .footlnk_smap_02 {
            width: 100%; } }
      .footlnk_all .footlnk_box.footlnk_box_01 .footlnk_smap .footlnk_smap_03 {
        width: calc((240 / 1160) * 100%); }
        @media screen and (max-width: 750px) {
          .footlnk_all .footlnk_box.footlnk_box_01 .footlnk_smap .footlnk_smap_03 {
            width: 100%; } }
      .footlnk_all .footlnk_box.footlnk_box_01 .footlnk_smap .footlnk_smap_04 {
        width: calc((250 / 1160) * 100%); }
        @media screen and (max-width: 750px) {
          .footlnk_all .footlnk_box.footlnk_box_01 .footlnk_smap .footlnk_smap_04 {
            width: 100%; } }
  .footlnk_all .footlnk_box.footlnk_box_02 {
    order: 4;
    width: calc((740 / 1160) * 100%);
    border-top: 1px solid #d4d4d4;
    height: 54px;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 750px) {
      .footlnk_all .footlnk_box.footlnk_box_02 {
        display: block;
        height: auto;
        width: 100%;
        border-top: none; } }
    .footlnk_all .footlnk_box.footlnk_box_02 ul {
      display: flex; }
      @media screen and (max-width: 750px) {
        .footlnk_all .footlnk_box.footlnk_box_02 ul {
          display: block; } }
      .footlnk_all .footlnk_box.footlnk_box_02 ul > li {
        padding-left: 1em;
        margin-left: 1em;
        border-left: 1px solid #d4d4d4;
        line-height: 1;
        display: block; }
        @media screen and (max-width: 750px) {
          .footlnk_all .footlnk_box.footlnk_box_02 ul > li {
            padding: 1.25em 0 1.25em .8em;
            border-left: none;
            margin: 0;
            font-size: calc((36 / 750) * 100vw);
            line-height: 1.3em;
            border-bottom: 1px solid #d4d4d4; } }
        .footlnk_all .footlnk_box.footlnk_box_02 ul > li:first-child {
          padding-left: 0;
          margin-left: 0;
          border-left: none; }
          @media screen and (max-width: 750px) {
            .footlnk_all .footlnk_box.footlnk_box_02 ul > li:first-child {
              padding-left: .8em; } }
        .footlnk_all .footlnk_box.footlnk_box_02 ul > li > a {
          padding-bottom: 0.2em;
          background: -webkit-gradient(linear, left top, left bottom, from(#141a21), to(#141a21)) 0 100%/0 1px no-repeat;
          background: linear-gradient(#141a21, #141a21) 0 100%/0 1px no-repeat;
          -webkit-transition: background 0.3s;
          transition: background 0.3s;
          position: relative;
          color: black;
          vertical-align: middle;
          text-decoration: none;
          margin: 0;
          display: block;
          font-size: 12px; }
          @media screen and (max-width: 750px) {
            .footlnk_all .footlnk_box.footlnk_box_02 ul > li > a {
              font-size: calc((36 / 750) * 100vw);
              font-weight: 600;
              padding-bottom: 0;
              position: relative; }
              .footlnk_all .footlnk_box.footlnk_box_02 ul > li > a::after {
                position: absolute;
                content: "";
                width: .5em;
                height: .5em;
                border-right: 2px #3a3a3a solid;
                border-bottom: 2px #3a3a3a solid;
                transform: rotate(-45deg);
                right: .8em;
                top: .3em; } }
          @media screen and (min-width: 750.1px) {
            .footlnk_all .footlnk_box.footlnk_box_02 ul > li > a:hover {
              background-size: 100% 1px; } }
  .footlnk_all .footlnk_box.footlnk_box_03 {
    order: 2;
    width: calc((740 / 1160) * 100%);
    border-top: 1px solid #d4d4d4;
    padding: 28px 0 14px; }
    @media screen and (max-width: 750px) {
      .footlnk_all .footlnk_box.footlnk_box_03 {
        display: block;
        width: 100%;
        padding: calc((100 / 750) * 100vw) 0 calc((50 / 750) * 100vw) s;
        border-top: none; } }
    .footlnk_all .footlnk_box.footlnk_box_03 ul.row-icon {
      display: flex; }
      .footlnk_all .footlnk_box.footlnk_box_03 ul.row-icon > li {
        width: 28px;
        margin: 0 8px; }
        @media screen and (max-width: 750px) {
          .footlnk_all .footlnk_box.footlnk_box_03 ul.row-icon > li {
            width: calc((62 / 750) * 100vw);
            margin: 0 calc((16 / 750) * 100vw); } }
        .footlnk_all .footlnk_box.footlnk_box_03 ul.row-icon > li a {
          display: block;
          width: 100%; }
          .footlnk_all .footlnk_box.footlnk_box_03 ul.row-icon > li a img {
            width: 100%; }
    .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns {
      display: flex; }
      @media screen and (max-width: 750px) {
        .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns {
          display: block;
          width: 100%; } }
      .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns .footlnk_sns_txt {
        width: calc((430 / 740) * 100%); }
        @media screen and (max-width: 750px) {
          .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns .footlnk_sns_txt {
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: calc((40 / 750) * 100vw); } }
        .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns .footlnk_sns_txt .foot_sns_ttl {
          font-size: 16px;
          font-weight: 600; }
          @media screen and (max-width: 750px) {
            .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns .footlnk_sns_txt .foot_sns_ttl {
              font-size: calc((28 / 750) * 100vw);
              margin-bottom: .5em; } }
        .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns .footlnk_sns_txt .foot_sns_txt {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: .05em; }
          @media screen and (max-width: 750px) {
            .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns .footlnk_sns_txt .foot_sns_txt {
              font-size: calc((24 / 750) * 100vw);
              letter-spacing: -.02em; } }
      .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns .footlnk_sns_lnk {
        width: calc((310 / 740) * 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap; }
        @media screen and (max-width: 750px) {
          .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns .footlnk_sns_lnk {
            width: 100%; } }
        .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns .footlnk_sns_lnk p {
          font-size: 12px;
          margin-top: 1em; }
          @media screen and (max-width: 750px) {
            .footlnk_all .footlnk_box.footlnk_box_03 .footlnk_sns .footlnk_sns_lnk p {
              font-size: calc((24 / 750) * 100vw);
              margin-top: .5em; } }
  .footlnk_all .footlnk_box.footlnk_box_04 {
    text-align: right;
    order: 3;
    width: calc((420 / 1160) * 100%);
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: right; }
    @media screen and (max-width: 750px) {
      .footlnk_all .footlnk_box.footlnk_box_04 {
        width: 100%;
        justify-content: center;
        text-align: center;
        height: calc((180 / 750) * 100vw);
        border-top: 1px solid #d4d4d4; } }
    .footlnk_all .footlnk_box.footlnk_box_04 .foot_jtblogo {
      width: 50%;
      height: 50px;
      background: transparent url(/common/img/logo_jtb2.svg) center center/auto no-repeat; }
      .footlnk_all .footlnk_box.footlnk_box_04 .foot_jtblogo a {
        display: block;
        height: 100%;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden; }
      @media screen and (max-width: 750px) {
        .footlnk_all .footlnk_box.footlnk_box_04 .foot_jtblogo {
          width: calc((520 / 750) * 100vw);
          height: 50px; } }
  .footlnk_all .footlnk_box.footlnk_box_05 {
    order: 5;
    width: calc((420 / 1160) * 100%);
    border-top: 1px solid #d4d4d4;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: right; }
    @media screen and (max-width: 750px) {
      .footlnk_all .footlnk_box.footlnk_box_05 {
        width: 100%;
        height: calc((76 / 750) * 100vw);
        justify-content: center;
        text-align: center;
        font-size: calc((20 / 750) * 100vw); } }
  .footlnk_all p.copywrite {
    font-size: 12px; }

#totop {
  position: fixed;
  z-index: +2;
  cursor: pointer;
  border-radius: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity .4s;
  background-image: linear-gradient(135deg, #666666, #a0a0a0);
  bottom: 40px;
  right: 40px;
  width: 60px;
  height: 60px; }
  #totop.act {
    opacity: .6;
    pointer-events: all; }
    @media screen and (max-width: 750px) {
      #totop.act {
        opacity: .8; } }
  #totop > i {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateY(0);
    transition: transform .3s; }
    #totop > i::before {
      position: absolute;
      content: "";
      transform: rotate(45deg);
      border-top: 2px solid white;
      border-left: 2px solid white;
      width: 20%;
      height: 20%;
      display: block;
      left: 37.5%;
      top: 32%; }
    #totop > i::after {
      position: absolute;
      content: "";
      background-color: white;
      width: 2px;
      height: 40%;
      display: block;
      left: calc(50% - 1px);
      top: 32.5%; }
  #totop:hover {
    opacity: 1; }
    @media screen and (min-width: 750.1px) {
      #totop:hover > i {
        transform: translateY(-4px); } }
  @media screen and (max-width: 750px) {
    #totop {
      bottom: 5vw;
      right: 5vw;
      width: 10vw;
      height: 10vw; } }

#process #totop {
  display: none; }

/* フッター 英語 */
html[lang=en] .footlnk_all .footlnk_box.footlnk_box_04 .foot_jtblogo {
  background: transparent url(/common/img/logo_jtb2_en.svg) center center/auto no-repeat; }

html[lang=ja] .h_ownedmedia.dsp {
  display: block !important; }

.imp_all {
  display: none;
  width: 100%;
  max-width: 920px;
  margin: 0 auto; }
  .imp_all.imp {
    display: block; }
  .imp_all dl {
    width: 100%;
    padding: 20px 0;
    display: flex; }
    @media screen and (max-width: 750px) {
      .imp_all dl {
        padding: calc((36 / 750) * 100vw);
        display: block; } }
    .imp_all dl > dt {
      width: calc((170 / 920) * 100%);
      display: flex;
      align-items: center; }
      @media screen and (max-width: 750px) {
        .imp_all dl > dt {
          width: 100%;
          justify-content: center;
          text-align: center; } }
      .imp_all dl > dt p {
        font-weight: 600;
        color: #c81428;
        font-size: 14px; }
        @media screen and (max-width: 750px) {
          .imp_all dl > dt p {
            font-size: calc((28 / 750) * 100vw);
            margin-bottom: .5em; } }
    .imp_all dl > dd {
      width: calc((750 / 920) * 100%); }
      @media screen and (max-width: 750px) {
        .imp_all dl > dd {
          width: 100%; } }
      .imp_all dl > dd ul li {
        margin-top: 1em;
        font-size: 13px; }
        @media screen and (max-width: 750px) {
          .imp_all dl > dd ul li {
            font-size: calc((24 / 750) * 100vw); } }
        .imp_all dl > dd ul li:first-child {
          margin-top: 0; }
        .imp_all dl > dd ul li a::after {
          content: "→"; }

#follow_jtbeing > div {
  position: fixed;
  bottom: 40px;
  right: 130px;
  width: 336px;
  padding: 15px 0;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  margin: 0;
  opacity: 0;
  z-index: +11;
  pointer-events: none;
  transition: opacity .4s; }
  @media screen and (max-width: 750px) {
    #follow_jtbeing > div {
      bottom: 20vw;
      width: 76vw;
      right: 7vw; } }
  #follow_jtbeing > div .cls {
    position: absolute;
    top: 8px;
    right: 16px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    opacity: 1;
    transition: opacity .4s; }
    @media screen and (min-width: 750.1px) {
      #follow_jtbeing > div .cls:hover {
        opacity: .4; } }
    #follow_jtbeing > div .cls:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 1px;
      height: 141.421256%;
      transform-origin: 10.5px 29px;
      transform: rotate(45deg);
      background-color: #6e2814; }
    #follow_jtbeing > div .cls:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 1px;
      height: 141.421256%;
      transform-origin: 3px 0px;
      transform: rotate(-45deg);
      background-color: #6e2814; }
  #follow_jtbeing > div.active {
    opacity: 1;
    pointer-events: all; }
  #follow_jtbeing > div dl.follow_jtbeing_new {
    width: 90%;
    margin: 0 auto 10px; }
    @media screen and (max-width: 750px) {
      #follow_jtbeing > div dl.follow_jtbeing_new {
        margin: 0 auto 2vw; } }
    #follow_jtbeing > div dl.follow_jtbeing_new dt {
      display: block;
      width: 100%; }
      #follow_jtbeing > div dl.follow_jtbeing_new dt p {
        font-size: 11px;
        line-height: 1.4em;
        margin: 0; }
        @media screen and (max-width: 750px) {
          #follow_jtbeing > div dl.follow_jtbeing_new dt p {
            font-size: 2.4vw; } }
        #follow_jtbeing > div dl.follow_jtbeing_new dt p.follow_jtbeing_ttl {
          display: block;
          font-size: 24px;
          font-weight: bold;
          margin: 0 0 .3em;
          color: #6e2814; }
          @media screen and (max-width: 750px) {
            #follow_jtbeing > div dl.follow_jtbeing_new dt p.follow_jtbeing_ttl {
              margin: 0 0 .3em;
              font-size: 6vw; } }
    #follow_jtbeing > div dl.follow_jtbeing_new dd {
      display: block;
      width: 100%;
      margin: 0; }
      #follow_jtbeing > div dl.follow_jtbeing_new dd figure {
        width: 100%;
        margin: 0;
        display: block;
        overflow: hidden; }
      #follow_jtbeing > div dl.follow_jtbeing_new dd figure img {
        width: 100%;
        margin: 0;
        display: block;
        transform: scale(1);
        transition: transform .3s; }
  #follow_jtbeing > div a {
    display: block;
    margin: 0;
    text-decoration: none;
    color: #6e2814; }
    #follow_jtbeing > div a .sdgs__link {
      font-size: 13px;
      margin: 0 auto;
      width: 70%;
      padding: .6em 0;
      display: block;
      color: #cc0000;
      border: 1px solid #cc0000;
      border-radius: 50px;
      text-align: center;
      transition: .2s;
      margin: 0 auto;
      position: relative; }
      @media screen and (max-width: 750px) {
        #follow_jtbeing > div a .sdgs__link {
          padding: .3em 0;
          font-size: 3.5vw; } }
      #follow_jtbeing > div a .sdgs__link:after {
        content: "→";
        right: 2em;
        top: .55em;
        position: absolute;
        width: 1em;
        color: #cc0000;
        transition: color .2s, right .2s; }
        @media screen and (max-width: 750px) {
          #follow_jtbeing > div a .sdgs__link:after {
            right: .6em;
            top: .3em; } }
    @media screen and (min-width: 750.1px) {
      #follow_jtbeing > div a:hover .sdgs__link {
        color: #fff;
        background: #cc0000; }
        #follow_jtbeing > div a:hover .sdgs__link:after {
          color: #fff;
          right: 1.5em; }
      #follow_jtbeing > div a:hover dl.follow_jtbeing_new dd figure img {
        transform: scale(1.03); } }
