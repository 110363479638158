@charset "utf-8";

// -------------------- [ Common ]
/* 共通カルーセル用ドットアニメーション */
@keyframes dotsanime{
    0% { width: 0; }
    100% { width: 100%; }
}




// -------------------- [ function ]
@function spvw($arg) {
	@return calc((#{$arg} / 750) * 100vw);
}
@function mdvw($a1, $a2) {
	@return calc((#{$a1} / #{$a2}) * 100vw);
}
@function pcnt($a1, $a2) {
	@return calc((#{$a1} / #{$a2}) * 100%);
}




// -- [ Break Point ]
$breakPoints: (
	'sp': 'screen and (max-width: 750px)',
	'pc': 'screen and (min-width: 750.1px)',

	// 'sp': 'screen and (max-width: 750px)',
	'md': 'screen and (min-width: 750.1px) and (max-width: 1200px)',
	'md2': 'screen and (min-width: 750.1px) and (max-width: 1400px)',
	'md3': 'screen and (min-width: 750.1px) and (max-width: 1020px)',
	'md4': 'screen and (min-width: 750.1px) and (max-width: 1115px)',
	'md5': 'screen and (min-width: 750.1px) and (max-width: 1060px)',
	'md6': 'screen and (min-width: 750.1px) and (max-width: 960px)',
	'lg': 'screen and (min-width: 1200.1px)',
	// 'pc': 'screen and (min-width: 750.1px)'
	
);

@mixin mq($breakPoint: 'sp') {
	@media #{map-get($breakPoints, $breakPoint)} {
		@content;
	}
}



@mixin w($mw, $w:100) {
	max-width: calc(#{$mw}*1px);
	width: calc(#{$w}*1%);
}
@mixin sp_w($w) {
	width: spvw($w);
}
@mixin sp_h($h) {
	height: spvw($h);
}
@mixin w_center($mw, $w:100) {
	@include w($mw, $w);
	margin-left: auto;
	margin-right: auto;
}
.clearText {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}
@mixin clearText() {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}










// -- [ InView ]


// -- [ Hover ]
@mixin opacitySetting {
	transition: .3s;
}
@mixin opacity {
	opacity: .4;
}

// Image Scale UP
@mixin imgScaleUpSetting {
	transition-duration: .3s;
}
@mixin imgScaleUp {
	transform: scale(1.1);
}

// Button Fillter
@mixin buttonFillterSeting($delay: 0s, $duration: .3s) {
	transition-delay: $delay;
	transition-duration: $duration;
}
@mixin buttonFillter {
	transform: scale(1.1);
}

// -------------------- [ Layout ]

// -- [ Font ]
@mixin remfs($fontSize) {
	font-size: $fontSize + px;
	font-size: ($fontSize / 10) * 1rem;
}
// @mixin resfs($device, $fontSize) {
// 	@if ($device == sp) {
// 		font-size: $fontSize;
// 		font-size: ($fontSize / $wSp) * 100 + vw;
// 	} @else if ($device == pc) {
// 		font-size: $fontSize;
// 		font-size: ($fontSize / $wPc) * 100 + vw;
// 	}
// }
@mixin resfs($fontSize) {
	font-size: $fontSize + px;
	font-size: ($fontSize / $wPc) * 100vw;
}

@mixin resfsu($fontSize) {
	font-size: $fontSize + px;
	font-size: ($fontSize / 1385) * 100vw;
}

// -- [ Flexible Box Layout ]
@mixin flexbox($wrap: wrap, $justify: space-between) {
	display: flex;
	flex-wrap: $wrap;
	justify-content: $justify;
}

// -- [  ]
@mixin clearfix {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}

// -- [ Grid Layout ]
@mixin grid($rows, $columns) {
	display: grid;
	grid-template-rows: $rows;
	grid-template-columns: $columns;
}
@mixin grid-item($row, $column) {
	grid-row: $row;
	grid-column: $column;
}

// -- [ Position ]
@mixin posAbsolute($top: null, $right: null, $bottom: null, $left: null) {
	position: absolute;
	@if $top != null {
		top: $top;
	}
	@if $right != null {
		right: $right;
	}
	@if $bottom != null {
		bottom: $bottom;
	}
	@if $left != null {
		left: $left;
	}
}
@mixin posCenter($top: null, $right: null, $bottom: null, $left: null, $x: -50%, $y: -50%) {
	position: absolute;
	@if $top != null {
		top: $top;
	}
	@if $right != null {
		right: $right;
	}
	@if $bottom != null {
		bottom: $bottom;
	}
	@if $left != null {
		left: $left;
	}
	transform: translate($y, $x);
}

// -- [ Width ]
@function p($width, $wSize: $wInner) {
	@return calc($width/$wSize*100%);
}

// -------------------- [ Design ]

// -- [ Background ]

// -------------------- [ Other ]
