@charset "UTF-8";

#follow_jtbeing {
    > div {
        position: fixed;
        bottom: 40px;
        right: 130px;
        width: 336px;
        padding: 15px 0;
        background-color: #fff;
        -webkit-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
        box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
        margin: 0;
        opacity: 0;
        z-index: +11;
        pointer-events: none;
        transition: opacity .4s;
        @include mq(sp) {
            bottom: 20vw;
            width: 76vw;
            right: 7vw;
        }
        .cls {
            position: absolute;
            top: 8px;
            right: 16px;
            width: 22px;
            height: 22px;
            cursor: pointer;
            opacity: 1;
            transition: opacity .4s;
            @include mq(pc) {
                &:hover {
                    opacity: .4;
                }
            }
            &:before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 1px;
                height: 141.421256%;
                transform-origin: 10.5px 29px;
                transform: rotate(45deg);
                background-color:  #6e2814;
            }
            &:after {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 1px;
                height: 141.421256%;
                transform-origin: 3px 0px;
                transform: rotate(-45deg);
                background-color:  #6e2814;
            }
        }
        &.active {
            opacity: 1;
            pointer-events: all;
        }
        dl {
            &.follow_jtbeing_new {

                width: 90%;
                margin: 0 auto 10px;
                @include mq(sp) {
                    margin: 0 auto 2vw;
                }
                dt {
                    display: block;
                    width: 100%;
                    p {
                        font-size: 11px;
                        line-height: 1.4em;
                        margin: 0;
                        @include mq(sp) {
                            font-size: 2.4vw;
                        }
                        &.follow_jtbeing_ttl {
                            display: block;
                            font-size: 24px;
                            font-weight: bold;
                            margin: 0 0 .3em;
                            color: #6e2814;
                            @include mq(sp) {
                                margin: 0 0 .3em;
                                font-size: 6vw;
                            }
                        }
                    }
                }
                dd {
                    display: block;
                    width: 100%;
                    margin: 0;
                    figure {
                        width: 100%;
                        margin: 0;
                        display: block;
                        overflow: hidden;
                    }
                    figure img {
                        width: 100%;
                        margin: 0;
                        display: block;
                        transform: scale(1);
                        transition: transform .3s;
                    }
                }
            }
        }
        a {
            display: block;
            margin: 0;
            text-decoration: none;
            color: #6e2814;
            .sdgs__link {
                font-size: 13px;
                margin: 0 auto;
                width: 70%;
                padding: .6em 0;
                display: block;
                color: #cc0000;
                border: 1px solid #cc0000;
                border-radius: 50px;
                text-align: center;
                transition: .2s;
                margin: 0 auto;
                position: relative;
                @include mq(sp) {
                    padding: .3em 0;
                    font-size: 3.5vw;
                }
                &:after {
                    content: "→";
                    right: 2em;
                    top: .55em;
                    position: absolute;
                    width: 1em;
                    color: #cc0000;
                    transition: color .2s, right .2s;
                    @include mq(sp) {
                        right: .6em;
                        top: .3em;
                    }
                }
            }
            @include mq(pc) {
                &:hover {
                    .sdgs__link {
                        color: #fff;
                        background: #cc0000;
                        &:after {
                            color: #fff;
                            right: 1.5em;
                        }
                    }
                    dl {
                        &.follow_jtbeing_new {
                            dd {
                                figure img {
                                    transform: scale(1.03);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // &.ptn2 {
    //     @include mq(sp) {
    //         > div {
    //             dl {
    //                 &.follow_jtbeing_new {
    //                     dt {
    //                         display: block;
    //                         width: 100%;
    //                     }
    //                     dd {
    //                         display: none;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}
