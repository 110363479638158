@charset "utf-8";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	letter-spacing: .1em;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
html {
	-webkit-text-size-adjust: 100%;
}
body {
	line-height: 1.5;
	font-family: 'Noto Sans JP', sans-serif, 'hirakaku', 'YuGothic';
	font-weight: normal;
	color: $defcolor;
}
ol, ul {
	list-style: none;
}
select {
	outline: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 100%;
}
a {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}
sup {
	vertical-align: top;
	font-size: 74%;
}
button {
	color: $defcolor;
}
::placeholder {
	color: #aaaaaa;
}