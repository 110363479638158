@charset "UTF-8";


.pc, .pc_only {
	display: block;
	@include mq(sp) {
		display: none;
	}
}
.sp, .sp_only {
	display: none;
	@include mq(sp) {
		display: block;
	}
}

.note01 {
	font-size: 12px;
	@include mq(sp) {
		font-size: spvw(20);
	}
}

.clearfix:after {
	display: block;
	content: "";
	clear: both;
}
.red { color: rgba(255,0,0,1); }
.lthrough { text-decoration: line-through; }

.wb_break { word-break: break-all; }
.wb_keep { word-break: keep-all; }
.wb_word { word-break: break-word; }

.nowrap { white-space: nowrap; }


/* !anchor ------------------------------------------------------------ */
.anch_offset {
	margin-top: -75px !important;
	padding-top: 75px !important;
	display: block;
	height: 0;
	line-height: 0;
	@include mq(sp) {
		margin-top: -10vw !important;
		padding-top: 10vw !important;
	} 
}
/* !Inline Align ------------------------------------------------------------ */
.taL { text-align: left   !important;}
.taC { text-align: center !important;}
.taR { text-align: right  !important;}
.vaT { vertical-align: top    !important;}
.vaM { vertical-align: middle !important;}
.vaB { vertical-align: bottom !important;}
.vaT_pc { @include mq(pc) { vertical-align: top !important;} }
.vaM_pc { @include mq(pc) { vertical-align: middle !important;} }
.vaB_pc { @include mq(pc) { vertical-align: bottom !important;} }
.blockC { margin-right: auto; margin-left: auto; text-align: left;}
* html .blockC { margin-right: 0; margin-left: 0;}
.blockR { margin-left: auto; text-align: left;}
* html .blockR { margin-left: 0;}

/* for TimeMCE ------------------------------------------------------------ */
.ed__style__center { text-align: center; display: block; width: 100%; }
.ed__style__right { text-align: right; display: block; width: 100%; }
.ed__style__left { text-align: left; display: block; width: 100%; }
.ed__style__del { text-decoration: line-through; }
.ed__style__underline_solid { text-decoration: underline solid; }
.ed__style__underline_double { text-decoration: underline double; }
.ed__style__underline_dotted { text-decoration: underline dotted; }
.ed__style__underline_dashed { text-decoration: underline dashed; }
.ed__style__border { border: #323232 1px solid; padding: 0 .3em .15em .4em; margin: 0 .2em; }
.ed__style__borderbox { border: #323232 1px solid; padding: 0.2em .5em 0.2em .7em; display: block; text-align: left; margin: .5em 0; }
.ed__style__gradation__red { background-color: rgba(190, 42, 28, 1); background-image: linear-gradient(90deg, rgba(190, 42, 28, 1), rgba(236, 157, 57, 1)); color: #fff; font-weight: bold; padding: .4em .5em .45em .8em; display: block; margin: 1.5em 0 .5em; }
.ed__style__gradation__blue { background-color: rgba(0, 39, 134, 1); background-image: linear-gradient(90deg, rgba(0, 39, 134, 1), rgba(1, 147, 220, 1)); color: #fff; font-weight: bold; padding: .4em .5em .45em .8em; display: block; margin: 1.5em 0 .5em; }
.ed__style__gradation__green { background-color: rgba(32, 74, 50, 1); background-image: linear-gradient(90deg, rgba(32, 74, 50, 1), rgba(78, 153, 1, 1)); color: #fff; font-weight: bold; padding: .4em .5em .45em .8em; display: block; margin: 1.5em 0 .5em; }



