@charset "utf-8";


// 重要なお知らせ
.imp_all {
	display: none;
	width: 100%;
	max-width: 920px;
	margin: 0 auto;
	&.imp {
		display: block;
	}
	dl {
		width: 100%;
		padding: 20px 0;
		display: flex;
		@include mq(sp) {
			padding: spvw(36);
			display: block;
		}
		> dt {
			width: pcnt(170, 920);
			display: flex;
			align-items: center;
			@include mq(sp) {
				width: 100%;
				justify-content: center;
				text-align: center;
			}
			p {
				font-weight: 600;
				color: #c81428;
				font-size: 14px;
				@include mq(sp) {
					font-size: spvw(28);
					margin-bottom: .5em;
				}
			}
		}
		> dd {
			width: pcnt(750, 920);
			@include mq(sp) {
				width: 100%;
			}
			ul {
				li {
					margin-top: 1em;
					font-size: 13px;
					@include mq(sp) {
						font-size: spvw(24);
					}
					&:first-child {
						margin-top: 0;
					}
					a {
						&::after {
							content: "→";
						}
					}
				}
			}
		}
	}
}
