@charset "utf-8";

@font-face {
	font-family: 'hirakaku';
	src: url('font/hirakaku_w3.woff') format('woff'), url('font/hirakaku_w3.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'hirakaku';
	src: url('font/hirakaku_w6.woff') format('woff'), url('font/hirakaku_w6.woff2') format('woff2');
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: 'YuGothic';
	src: url('font/YuGothicMedium.woff') format('woff'), url('font/YuGothicMedium.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'YuGothic';
	src: url('font/YuGothicBold.woff') format('woff'), url('font/YuGothicBold.woff2') format('woff2');
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: 'Roboto';
	src: url('font/Roboto-Medium.woff') format('woff'), url('font/Roboto-Medium.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('font/Roboto-Black.woff') format('woff'), url('font/Roboto-Black.woff2') format('woff2');
	font-style: normal;
	font-weight: bold;
}



